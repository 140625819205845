import recursiveAccesor from './recursiveAccessor';

export function sortText(textFieldToCompare, descending) {
    const directionModifier = descending ? -1 : 1;
    return (a, b) => {
        let av = recursiveAccesor(a, textFieldToCompare);
        let bv = recursiveAccesor(b, textFieldToCompare);

        const noAv = !av && av !== '';
        const noBv = !bv && bv !== '';

        return noAv || noBv
            ? noAv && noBv
                ? 0
                : noAv
                    ? 1
                    : -1
            : internalSort(av, bv, directionModifier);
    };
}

export function sortByTextYearProperty(textFieldToCompare, descending) {
    const directionModifier = descending ? -1 : 1;
    return (a, b) => {
        let av = recursiveAccesor(a, textFieldToCompare);
        let bv = recursiveAccesor(b, textFieldToCompare);

        const yearRegex = /^(\d+)\s?(?:(?:B\.?)?C\.?E?\.?|A\.?D\.?|B\.?C\.?)?$/;
        const noAv = !av || !yearRegex.test(av);
        const noBv = !bv || !yearRegex.test(bv);

        if (noAv || noBv)
            return noAv && noBv ? 0 : noAv ? 1 : -1

        // TODO: This could be extended to parse dates

        const bcRegex = /^\d+\s?B\.?C\.?E?\.?$/;
        const aIsBc = bcRegex.test(av);
        const bIsBc = bcRegex.test(bv);

        return directionModifier * (aIsBc && bIsBc ? -1 : 1) * (
            // If only one is BC then it comes first
            (aIsBc || bIsBc) && !(aIsBc && bIsBc)
                ? aIsBc ? -1 : 1
                // Otherwise compare by year
                : Number(yearRegex.exec(av)[1]) - Number(yearRegex.exec(bv)[1])
        );
    };
}

export function sortFieldsByOrder(intialFieldArray) {
    const initialOrder = intialFieldArray.slice();
    return (a, b) => {
        if (!!a.order !== !!b.order && a.order !== 0 && b.order !== 0)
            return !a.order ? 1 : -1

        if (a.order >= 0 && b.order >= 0 && a.order !== b.order)
            return a.order - b.order;

        return initialOrder.indexOf(b._id) - initialOrder.indexOf(a._id);
    }
}

export function sortFieldsAllCollatedNotes(fields) {
    fields.filter(f => f.type === 'Collated Notes')
        .forEach(f => sortFieldCollatedNotesValues(f));

    return fields;
}

export function sortFieldCollatedNotesValues(field) {
    if (!field._collatedNotesSorted) {
        field.value = field.value
            .sort((a, b) => {
                if (!!a.value !== !!b.value) return !a.value ? 1 : -1;

                const aFirstName = a.user.firstName;
                const bFirstName = b.user.firstName;
                if (a.user.firstName === b.user.firstName) {
                    a.duplicateFirstName = b.duplicateFirstName = true;

                    const aSurname = a.user.surname;
                    const bSurname = b.user.surname;
                    const dupeSurnameInitial = aSurname[0] === bSurname[0];

                    a.duplicateSurnameInitial =
                        a.duplicateSurnameInitial || dupeSurnameInitial;
                    b.duplicateSurnameInitial =
                        b.duplicateSurnameInitial || dupeSurnameInitial;

                    return aSurname < bSurname
                        ? -1
                        : aSurname > bSurname
                            ? 1
                            : 0;
                }

                return aFirstName < bFirstName
                    ? -1
                    : aFirstName > bFirstName
                        ? 1
                        : 0;
            })
            .map(v => ({
                ...v,
                displayName: v.duplicateSurnameInitial
                    ? `${v.user.firstName} ${v.user.surname}`
                    : v.duplicateFirstName
                        ? `${v.user.firstName} ${v.user.surname[0]}.`
                        : v.user.firstName,
            }));


        field._collatedNotesSorted = true;
    }
}


const internalSort = (a, b, directionModifier) => (
    (a, b, directionModifier) => directionModifier * (a < b ? -1 : a > b ? 1 : 0)
)((a + '').toLowerCase(), (b + '').toLowerCase(), directionModifier);
