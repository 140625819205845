<template>
    <v-snackbar v-model="showSnackbar">
        <div v-html="snackbarText"></div>
        <v-btn @click="showSnackbar = false"
               color="teal lighten-4"
               text
               multi-line>Close</v-btn>
    </v-snackbar>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'app-snackbar',
        computed: {
            showSnackbar: {
                get() {
                    return this.snackbarShown;
                },
                set(newVal) {
                    this.setSnackBackDetails({ isShown: newVal });
                },
            },
            ...mapState(['snackbarShown', 'snackbarText']),
        },
        methods: {
            ...mapMutations(['setSnackBackDetails']),
        },
    };
</script>