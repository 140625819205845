<template>
    <v-list-item @click="$emit('file-selected', id)"
                 :disabled="disabled || loading"
                 style="pointer-events: auto;">
        <!-- This ^ is because they change it and then you can't see the bloody title -->
        <v-list-item-content>
            <v-list-item-title v-text="name"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
            <v-icon v-if="!loading">fa fa-file-download</v-icon>
            <v-progress-circular v-else
                                 indeterminate
                                 width="3"
                                 size="24"></v-progress-circular>
        </v-list-item-icon>
    </v-list-item>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'file-item',
        props: {
            id: { type: [String, Number], required: true },
            name: { type: String, required: true },
            disabled: Boolean,
        },
        computed: {
            ...mapState('files', {
                loading(state) {
                    return state.downloading[this.id];
                },
            }),
        },
    };
</script>