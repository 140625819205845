import * as msal from "@azure/msal-browser";
import { getLazy } from '../config';


const promisedInstance = getLazy()
    .then(config => ({
        config,
        msalInstance: new msal.PublicClientApplication(config.auth),
    }));

let currentAccount = undefined;

const loginRequest = {
    scopes: ["user.read"],
};

const handleSignIn = (async () => {
    try {
        const { msalInstance } = await promisedInstance;
        const tokenResponse = await msalInstance.handleRedirectPromise();
        // We have just completed sign in and have a new token
        if (tokenResponse) {
            currentAccount = tokenResponse.account;
            return;
        }

        const currentAccounts = msalInstance.getAllAccounts();

        // We haven't arrived via sign in & we don't have any signed in accounts saved
        if (currentAccounts.length < 1)
            return msalInstance.loginRedirect(loginRequest);

        // It is possible to have more than 1 current account, although I'm not sure how, so just use the first 1
        currentAccount = currentAccounts[0];
    }
    catch (err) {
        // This is a bit dangerous - You can end up going round in circles if the load fales
        // You need a failed page that doesn't do an initial load
        console.log(`Auth Error: ${err}`);
        // location.href = '/401';
        alert('Auth Failed');
    }
})();

export const acquireToken = async () => {
    await handleSignIn;
    const { config, msalInstance } = await promisedInstance;
    const request = {
        scopes: config.api.submissions.scopes,
        account: currentAccount,
    };
    try {
        var token = await msalInstance.acquireTokenSilent(request)
        return token ? token.accessToken : undefined;
    }
    catch (error) {
        console.log('Could not acquire an access token');

        if (error instanceof msal.InteractionRequiredAuthError) {
            console.log('Needs extra interaction (scopes have not been authorised)')
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(request)
        }
    }
}
