import Vue from 'vue';

const Checkbox = function (valueRequired) {
    this.valueRequired = this.valueRequired || valueRequired;

    Vue.set(this, 'valid', !this.required || this.value === true);
    Vue.set(this, 'validationMessage', this.valid ? null : `'${this.label}' must be selected.`);
    return this.valid;
}

const functions = {
    Checkbox,
    Grade: function (valueRequired) {
        this.valueRequired = this.valueRequired || valueRequired;

        Vue.set(this, 'valid', !this.required || !this.valueRequired || !!this.value);
        Vue.set(this, 'validationMessage', this.valid ? null : `'${this.label}' must be selected.`);
        return this.valid;
    },
    'Multiline Text': function (valueRequired) {
        if (valueRequired) Vue.set(this, 'value', this.value || '');

        Vue.set(this, 'valid', !this.required || this.value !== '');
        Vue.set(this, 'validationMessage', this.valid ? null : `${this.label} must be added`);
        return this.valid;
    },
    'Rich Text': function (valueRequired) {
        if (valueRequired) Vue.set(this, 'value', this.value || '');

        Vue.set(this, 'valid', !this.required || (this.value ? this.value.replace(/<\/?[-\w]+>/gim, '') : this.value) !== '');
        Vue.set(this, 'validationMessage', this.valid ? null : `${this.label} must be added`);
        return this.valid;
    },
    'Submission Warning Flag': Checkbox,
    'User Select': function (valueRequired) {
        if (valueRequired) Vue.set(this, 'value', this.value || '');

        Vue.set(this, 'valid', !this.required || this.value !== '');
        Vue.set(this, 'validationMessage', this.valid ? null : 'You must select someone.');
        return this.valid;
    },
    // Collated notes as an actual field is only a read only view over the actual notes
    // Any validation will be done on multiline text
    'Collated Notes': () => true,
}

export default function fieldValidationFunction(fieldType) {
    return functions[fieldType] || (() => { throw new Error('Validation function does not exist for field type.') });
}