import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        downloading: {},
        uploading: {},
    },
    mutations: {
        setDownloading(state, { fileId, isDownloading }) {
            Vue.set(state.downloading, fileId, isDownloading);
        },
        setUploading(state, { fileId, isUploading }) {
            Vue.set(state.uploading, fileId, isUploading);
        },
    },
}