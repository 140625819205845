<template>
    <app-navigation heading="My Tasks"
                    class="mb-4">
        <v-container class="mh-100"
                     :class="{'d-flex align-center justify-center': !queue.length }">
            <v-expansion-panels v-if="queue.length"
                                :multiple="true"
                                :value="expandedPanels">
                <template v-for="task in queue">
                    <task-general-notification v-if="task.type === 'General Notification'"
                                               :key="task._id"
                                               :task-id="task._id"></task-general-notification>
                    <task-submission-withdrawn v-else-if="(task.type === 'Submission Details' || task.type === 'Submission Step' || task.type === 'Collect Collated Notes') && task.status === 'Withdrawn'"
                                               :key="task._id"
                                               :task-id="task._id"></task-submission-withdrawn>
                    <task-submission-details v-else-if="task.type === 'Submission Details'"
                                             :key="task._id"
                                             :task-id="task._id"></task-submission-details>
                    <task-submission-step v-else-if="task.type === 'Submission Step'"
                                          :key="task._id"
                                          :task-id="task._id"></task-submission-step>
                    <task-collated-notes v-else-if="task.type === 'Collect Collated Notes'"
                                         :key="task._id"
                                         :task-id="task._id"></task-collated-notes>
                    <v-card v-else
                            :key="task._id">
                        <v-card-title>No Style</v-card-title>
                    </v-card>
                </template>
            </v-expansion-panels>
            <div v-else
                 class="d-flex align-center justify-center">
                <div>
                    <h2 class="text-center">You have no assigned tasks left to complete!</h2>
                    <em>Time to read a book perhaps?</em>
                </div>
            </div>
        </v-container>
    </app-navigation>
</template>
<script>
    import { mapState } from 'vuex';

    import appNavigation from '@/components/app-navigation';
    import taskCollatedNotes from '@/modules/my-tasks/components/task-collect-collated-notes';
    import taskGeneralNotification from '@/modules/my-tasks/components/task-general-notification';
    import taskSubmissionDetails from '@/modules/my-tasks/components/task-submission-details';
    import taskSubmissionStep from '@/modules/my-tasks/components/task-submission-step';
    import taskSubmissionWithdrawn from '@/modules/my-tasks/components/task-submission-withdrawn';

    export default {
        name: 'task-list',
        components: {
            appNavigation,
            taskCollatedNotes,
            taskGeneralNotification,
            taskSubmissionDetails,
            taskSubmissionStep,
            taskSubmissionWithdrawn,
        },
        data() {
            return {
                expandedPanels: [],
            };
        },
        computed: {
            ...mapState('myTasks', ['queue']),
        },
        // Reload the tasks when re-accessing the page
        beforeRouteEnter(to, from, next) {
            if (from.path !== to.path) to.params.reloadTasks = true;
            next();
        },
        beforeCreate() {
            if (this.$route.params.reloadTasks)
                this.$store.dispatch('myTasks/reload');
        },
        // End of task reload
        created() {
            let i = 0;
            this.queue.forEach(() => {
                this.expandedPanels.push(i++);
            });
        },
    };
</script>