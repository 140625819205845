<template>
    <v-input :error-messages="field.validationMessage"
             :value="selectedUserValue.value"
             :hide-details="hideDetails"
             :disabled="disabled"
             class="mt-1">
        <v-card class="cn-card readonly w-100"
                flat
                outlined>
            <v-card-subtitle class="cn-card-label">{{field.label}}</v-card-subtitle>
            <v-toolbar height="40"
                       flat
                       class="cn-editor-tabs">
                <v-tabs v-model="index"
                        show-arrows
                        class="space-btn-right">
                    <v-tab v-for="value in userValues"
                           :key="value.user._id"
                           :disabled="!value.value"
                           :title="`${value.displayName} has ${value.finalised ? 'finalised notes' : value.skipped ? 'skipped these notes' : !value.value ? 'not entered any notes.' : 'notes that aren\'t finalised'}`">
                        {{value.displayName}}
                        <v-icon x-small
                                class="ml-2">far fa-{{value.finalised ? 'check-' : value.skipped ? 'times-' : ''}}circle</v-icon>
                    </v-tab>
                </v-tabs>
                <v-menu v-if="!readonly"
                        v-model="adjustMenu"
                        offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text
                               v-bind="attrs"
                               v-on="on">
                            <v-icon small>fas fa-ellipsis-v</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click.stop="$emit('collated-notes-modify-editors', {add: true}); adjustMenu=false">
                            <v-list-item-title>Add Editor</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.stop="$emit('collated-notes-modify-editors', {remove: true}); adjustMenu=false"
                                     :disabled="!userValues.length">
                            <v-list-item-title>Remove Editor</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
            <v-card-text>
                <span v-if="selectedUserValue.skipped"><em>{{selectedUserValue.user.firstName}} has skipped this round of notes...</em></span>
                <span v-else-if="selectedUserValue.value"
                      v-html="selectedUserValue.value"
                      class="cn-value"></span>
                <span v-else><em>{{selectedUserValue.user.firstName}} has not entered any notes...</em></span>
            </v-card-text>
        </v-card>
    </v-input>
</template>

<script>
    export default {
        name: 'field-collated-notes',
        props: {
            disabled: Boolean,
            field: Object,
            hideDetails: Boolean,
            readonly: Boolean,
        },
        data: () => ({
            adjustMenu: false,
            index: 0,
        }),
        computed: {
            selectedUserValue() {
                return this.userValues[this.index];
            },
            userValues() {
                return this.field.value;
            },
        },
    };
</script>
<style scoped>
    .cn-header {
        padding: 0.5em;
        font-size: 1.2em;
    }

    .cn-card-label {
        position: absolute;
        top: -0.9em;
        left: 0.6em;
        padding: 0 0.2em;
        font-size: 0.8em;
        background: linear-gradient(
            to bottom,
            white 0% 53%,
            rgba(255, 255, 255, 0.2) 53%,
            transparent 100%
        );
        z-index: 2;
    }

    .cn-value {
        white-space: pre-wrap;
    }

    .cn-editor-tabs {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .cn-card .v-card__text {
        color: black;
    }

    .space-btn-right {
        max-width: calc(100% - 25px);
    }
</style>
<style>
    .tiptap-vuetify-editor__toolbar {
        border-bottom: 0.5px solid #dfdfdf;
    }

    .theme--light.v-card.v-card--outlined.cn-card {
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.38);
    }

    .theme--light.v-card.v-card--outlined.cn-card.cn-focused {
        border-color: #1976d2;
        caret-color: #1976d2;
    }

    .theme--light.v-card.v-card--outlined.cn-card.cn-disabled,
    .cn-card-label.disabled {
        color: rgba(0, 0, 0, 0.38) !important;
        border-color: rgba(0, 0, 0, 0.26);
    }

    .theme--light.v-card.v-card--outlined.cn-card.cn-invalid {
        border-color: rgb(255, 82, 82);
        border-width: 2px;
    }

    .theme--light.v-card.v-card--outlined.cn-card.cn-invalid .cn-card-label {
        color: rgb(255, 82, 82);
    }

    .cn-editor-tabs .v-tab {
        text-transform: none;
        pointer-events: auto;
    }
</style>