import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

const vuetify = new Vuetify({
    icons: {
        iconfont: 'fa',
    },
});

// // import TipTap plugin (Rich Text Editor)
// import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// // don't forget to import CSS styles
// import 'tiptap-vuetify/dist/main.css';

// Vue.use(TiptapVuetifyPlugin, {
//     // the next line is important! You need to provide the Vuetify Object to this place.
//     vuetify, // same as "vuetify: vuetify"
//     // optional, default to 'md' (default vuetify icons before v2.0.0)
//     iconsGroup: 'md'
// })

export default vuetify;