<template>
    <app-navigation no-menu>
        <v-container>
            <v-row align="center"
                   justify="center">
                <v-col lg="6"
                       md="10">
                    <v-card v-if="created">
                        <v-card-title>Set up</v-card-title>
                        <v-card-text>Wonderful, that's all gone through. You can go to the home page now, but there won't be much to do until an administrator configures your access</v-card-text>
                        <card-actions>
                            <v-btn text
                                   key="Home"
                                   color="primary"
                                   to="/">Home</v-btn>
                        </card-actions>
                    </v-card>
                    <v-card v-else>
                        <v-card-title>Welcome</v-card-title>
                        <v-card-text>
                            <p>Congrats for making it this far! This is the system for managing our submissions.</p>
                            <p class="mb-0">As this is the first time we've seen you, please can you fill in your details:</p>
                            <v-text-field :value="username"
                                          disabled
                                          label="Email Address"
                                          class="pt-2 mt-4">
                            </v-text-field>
                            <v-row>
                                <v-col cols="12"
                                       sm="6"
                                       class="py-0">
                                    <v-text-field v-model="firstName"
                                                  :disabled="loading"
                                                  v-focus
                                                  label="First Name"
                                                  :error-messages="firstNameInvalid ? 'Please enter your first name' : ''"
                                                  class="pt-2 my-4"
                                                  counter="50"
                                                  maxlength="50">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       md="6"
                                       class="py-0">
                                    <v-text-field v-model="surname"
                                                  :disabled="loading"
                                                  label="Surname"
                                                  :error-messages="surnameInvalid ? 'Please enter your surname' : ''"
                                                  class="pt-2 my-4"
                                                  counter="50"
                                                  maxlength="50">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <card-actions>
                            <v-btn text
                                   key="validate"
                                   color="primary"
                                   :disabled="isInvalid"
                                   :loading="loading"
                                   @click="enter">Save</v-btn>
                        </card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </app-navigation>
</template>

<script>
    // @ is an alias to /src
    import appNavigation from '@/components/app-navigation';

    export default {
        name: 'unknown-user',
        props: {
            username: String,
        },
        data() {
            return {
                accessKey: '',
                created: false,
                firstName: null,
                loading: false,
                surname: null,
            };
        },
        components: {
            appNavigation,
        },
        computed: {
            isInvalid() {
                return this.firstNameInvalid || this.surnameInvalid;
            },
            firstNameInvalid() {
                return this.firstName === '';
            },
            surnameInvalid() {
                return this.surname === '';
            },
        },
        methods: {
            enter() {
                this.firstName = this.firstName || '';
                this.surname = this.surname || '';

                if (!this.isInvalid) {
                    this.loading = true;
                    this.$http
                        .post('/users/current', {
                            firstName: this.firstName,
                            surname: this.surname,
                        })
                        .then(() => {
                            this.created = true;
                            this.loading = false;
                        })
                        .catch(err => this.$handleError(err, true))
                        .catch(err => {
                            const resp = err.response;
                            this.loading = false;
                            // If there is a duplicate, then the account is set up already, so all good
                            // Just pretend there isn't an issue
                            if (resp.status === 409 && resp.data.isDuplicate)
                                this.created = true;
                            else
                                this.$store.commit('updateStatus', {
                                    isErrored: true,
                                });
                        });
                }
            },
        },
        created() {
            if (!this.username)
                this.$store.commit('updateStatus', { isErrored: true });
        },
    };
</script>
