<template>
    <card-actions v-if="confirming">
        <v-btn @click="process"
               color="primary"
               text
               :loading="loading">Save</v-btn>
        <template #secondary>
            <v-btn @click="cancelConfirm"
                   text>Back to Edit</v-btn>
        </template>
    </card-actions>
    <card-actions v-else-if="task.completeSelectedOutcome">
        <v-btn @click="selectOutcome(selectedOutcome._id)"
               color="primary"
               text
               :loading="loading"
               :disabled="!task.valid">{{selectedOutcome.name}}</v-btn>
        <template #secondary>
            <v-btn @click="cancelSelectedOutcome"
                   text>Cancel '{{selectedOutcome.name}}'</v-btn>
            <save-task-btn :task-id="taskId"></save-task-btn>
        </template>
    </card-actions>
    <actions-sequential v-else-if="outcomes.type === 'Sequential'"
                        :task-id="taskId"
                        :outcomes="outcomes"
                        :loading="loading"
                        :disabled="!task.valid"
                        @select-outcome="selectOutcome">
    </actions-sequential>
    <actions-thresholded v-else-if="outcomes.type === 'Thresholded'"
                         :task-id="taskId"
                         :loading="loading"
                         :disabled="!task.valid"
                         @select-outcome="selectOutcome"></actions-thresholded>
    <actions-multiple-choice v-else-if="outcomes.type === 'Multiple Choice'"
                             :task-id="taskId"
                             :outcomes="outcomes"
                             :loading="loading"
                             :disabled="!task.valid"
                             @select-outcome="selectOutcome"></actions-multiple-choice>
    <card-actions v-else>
        TODO!
    </card-actions>
</template>
<script>
    import { mapState } from 'vuex';

    import actionsMultipleChoice from './outcome-actions-multiple-choice';
    import actionsSequential from './outcome-actions-sequential';
    import actionsThresholded from './outcome-actions-thresholded';
    import saveTaskBtn from './save-task-btn';

    export default {
        name: 'outcome-actions',
        components: {
            actionsMultipleChoice,
            actionsSequential,
            actionsThresholded,
            saveTaskBtn,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isLoading: false,
            };
        },
        computed: {
            confirming() {
                return this.task.confirmTask;
            },
            loading: {
                get() {
                    return this.isLoading;
                },
                set(newValue) {
                    this.isLoading = newValue;
                    this.$emit('loading', newValue);
                },
            },
            outcomes() {
                return this.task.outcomes;
            },
            selectedOutcome() {
                return this.task.selectedOutcome;
            },
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
            }),
        },
        methods: {
            process() {
                this.loading = true;
                this.$store
                    .dispatch('myTasks/processStep', { taskId: this.taskId })
                    .then(result => {
                        this.loading = false;

                        if (result !== 'task invalid') {
                            result;
                        }
                    });
            },
            selectOutcome(selectedOutcomeId) {
                this.$store.dispatch('myTasks/outcomeSelect', {
                    taskId: this.taskId,
                    selectedOutcomeId,
                });
            },
            cancelConfirm() {
                this.$store.commit('myTasks/setConfirmTask', {
                    taskId: this.taskId,
                    isConfirming: false,
                });

                // If we aren't completing the outcome but we have selected an outcome then clear it
                // The outcome will be selected again when submitting the task, and if this is not done
                // An issue can occur when changing the grade of a piece
                if (!this.task.completeSelectedOutcome && this.task.selectedOutcome)
                    this.cancelSelectedOutcome();
            },
            cancelSelectedOutcome() {
                this.$store.dispatch('myTasks/outcomeCancel', {
                    taskId: this.taskId,
                });
            },
        },
    };
</script>