<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="min-width">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="`${task.notificationReason}: ${task.title}`"
                         class="text-truncate pr-2">
                        <span class="mr-2">{{task.notificationReason}}:</span><em>{{task.title}}</em>
                    </div>
                </v-card-title>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card-text class="py-0"
                         v-html="task.message"></v-card-text>
            <card-actions>
                <v-btn @click="done"
                       color="primary"
                       text
                       :loading="loading">{{task.okActionName || 'Ok'}}</v-btn>
            </card-actions>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'task-general-notification',
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            loading: false,
        }),
        computed: {
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
            }),
        },
        methods: {
            done() {
                this.loading = true;
                this.$store
                    .dispatch('myTasks/dismissTask', {
                        taskId: this.taskId,
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>