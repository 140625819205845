<template>
    <v-input :error-messages="field.validationMessage"
             :value="valueBound"
             :hide-details="hideDetails"
             class="mt-1">
        <!-- <tiptap-vuetify v-if="!readonly"
                        v-model="valueBound"
                        :extensions="extensions"
                        :card-props="{ class: { 
                            'rte-card': true, 
                            'rte-focused': !disabled && focused, 
                            'rte-disabled': disabled,
                            'rte-invalid':  !valid,
                            }, flat: true, outlined: true}"
                        :toolbar-attributes="{color: 'white'}"
                        :editor-properties="{ editable: !disabled }"
                        @init="captureEditor($event)"
                        class="w-100">
            <template v-if="field.label"
                      #toolbar-before>
                <v-card-subtitle class="rte-card-label"
                                 :class="{'primary--text': valid && focused && !disabled, disabled }">{{field.label}}</v-card-subtitle>
            </template>
        </tiptap-vuetify> -->
        <v-card class="rte-card readonly w-100"
                flat
                outlined>
            <v-card-subtitle class="rte-card-label">{{field.label}}</v-card-subtitle>
            <v-card-text v-html="valueBound">
            </v-card-text>
        </v-card>
    </v-input>
</template>

<script>
    // import the component and the necessary extensions
    // import {
    //     TiptapVuetify,
    //     Heading,
    //     Bold,
    //     Italic,
    //     Strike,
    //     Underline,
    //     Paragraph,
    //     BulletList,
    //     OrderedList,
    //     ListItem,
    //     Link,
    //     Blockquote,
    //     HardBreak,
    //     HorizontalRule,
    //     History,
    // } from 'tiptap-vuetify';

    export default {
        name: 'field-rich-text',
        // components: { TiptapVuetify },
        props: {
            disabled: Boolean,
            field: Object,
            hideDetails: Boolean,
            // readonly: Boolean,
            value: String,
        },
        data: () => ({
            readonly: true,
            // extensions: [
            //     History,
            //     Blockquote,
            //     Link,
            //     Underline,
            //     Strike,
            //     Italic,
            //     ListItem,
            //     BulletList,
            //     OrderedList,
            //     [
            //         Heading,
            //         {
            //             options: {
            //                 levels: [1, 2, 3],
            //             },
            //         },
            //     ],
            //     Bold,
            //     HorizontalRule,
            //     Paragraph,
            //     HardBreak,
            // ],
            // editor: undefined,
        }),
        computed: {
            // focused() {
            //     return this.editor ? this.editor.focused : false;
            // },
            valueBound: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                },
            },
            valid() {
                return this.field.valid;
            },
            validationMessage() {
                return this.field.validationMessage;
            },
        },
        // methods: {
        //     captureEditor(event) {
        //         this.editor = event.editor;
        //     },
        // },
        // watch: {
        //     disabled(newVal, oldVal) {
        //         if (newVal !== oldVal) this.editor.options.editable = !newVal;
        //     },
        // },
        created() {
            window.console.error('RTFs are not properly set up.');
            // remember blur, focus
        },
    };
</script>
<style scoped>
    .rte-card {
        padding-top: 1em;
    }

    .rte-header {
        padding: 0.5em;
        font-size: 1.2em;
    }

    .rte-card-label {
        position: absolute;
        top: -0.9em;
        left: 0.6em;
        padding: 0 0.2em;
        font-size: 0.8em;
        background: linear-gradient(
            to bottom,
            white 0% 52%,
            rgba(255, 255, 255, 0.2) 52%,
            transparent 100%
        );
        z-index: 2;
    }

    .rte-card .v-card__text {
        color: black;
    }
</style>
<style>
    .tiptap-vuetify-editor__toolbar {
        border-bottom: 0.5px solid #dfdfdf;
    }

    .theme--light.v-card.v-card--outlined.rte-card {
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.38);
    }

    .theme--light.v-card.v-card--outlined.rte-card.rte-focused {
        border-color: #1976d2;
        caret-color: #1976d2;
    }

    .theme--light.v-card.v-card--outlined.rte-card.rte-disabled,
    .rte-card-label.disabled {
        color: rgba(0, 0, 0, 0.38) !important;
        border-color: rgba(0, 0, 0, 0.26);
    }

    .theme--light.v-card.v-card--outlined.rte-card.rte-invalid {
        border-color: rgb(255, 82, 82);
        border-width: 2px;
    }

    .theme--light.v-card.v-card--outlined.rte-card.rte-invalid .rte-card-label {
        color: rgb(255, 82, 82);
    }
</style>