import Vue from 'vue';
import _ from 'lodash'

import { sortText } from '@/helpers/sorts';
import prependUrl from '@/store/organisationsPrependActiveUrlGetter';

import submissions from './submissions'
import syncing from './syncing'


export default {
    namespaced: true,
    modules: {
        submissions,
        syncing,
    },
    state: {
        accessKeyRegex: /^[0-9a-g]{32}$/i,
        allAccounts: {},
        activeAccountId: localStorage.getItem('activeSubmittableAccountId') || null,
        categories: {},
        projects: {}
    },
    getters: {
        accounts: (state, getters) => getters.currentOrgAccIds.reduce((accs, id) => { accs[id] = state.allAccounts[id]; return accs; }, {}),
        accountsArray: (state, getters) => getters.currentOrgAccIds.map(id => state.allAccounts[id]).filter(a => a !== undefined).sort(sortText('name')) || [],
        accountsCount: (s, getters) => getters.currentOrgAccIds.length,
        activeAccount: (state, getters) => {
            const id = state.activeAccountId;
            if (!id) return null;
            const acc = getters.accounts[id];
            return acc && acc.active ? acc : null;
        },
        activeAccountProject: (s, getters) => !getters.activeAccount ? null : getters.activeAccount.projectToFeed,
        activeAccounts: (s, getters) => getters.accountsArray.filter(a => a.active),
        activeAccountOptions: (s, getters) =>
            getters.activeAccounts
                .map(acc => { return { value: acc._id, text: acc.name } }),
        currentOrgAccIds: (s, g, rs, rootGetters) => (rootGetters['organisation/active'] || {}).submittableAccounts || [],
        getById: state => _id => state.allAccounts[_id],
        prependUrl,
        urlActiveAccount: (state, getters) => `${getters.urlAccounts}/${state.activeAccountId || 'NoActiveAccountId'}`,
        urlAccounts: (s, getters) => `${getters.urlBase}/accounts`,
        urlSubmittbleApiAccess: (state, getters) => `${getters.urlBase}/${state.activeAccountId || 'NoActiveAccountId'}`,
        urlBase: (s, getters) => getters.prependUrl('/submittable'),
    },
    mutations: {
        categoryLoad(state, category) {
            Vue.set(state.categories, category.category_id, category);
        },
        deleteAccount(state, { accountId }) {
            Vue.delete(state.allAccounts, accountId);
        },
        loadAccount(state, account) {
            Vue.set(state.allAccounts, account._id, account);
        },
        loadProject(state, project) {
            Vue.set(state.projects, project._id, project);
        },
        removeAccounts(state, { accountIds, removeAll }) {
            const removeAccount = (accountId) => { Vue.delete(state.allAccounts, accountId) }

            if (removeAll) _.forOwn(state.allAccounts, (v, key) => { removeAccount(key); });
            else if (_.isArray(accountIds)) accountIds.forEach(removeAccount);
            else if (_.isString(accountIds)) removeAccount(accountIds);
        },
        setActiveAccount(state, { accountId }) {
            if (accountId) {
                const account = state.allAccounts[accountId];
                // Don't switch if the account isn't active or if 
                // the active account is the one being swtiched to
                if (!account || !account.active
                    || accountId === state.activeAccountId) return;
                state.activeAccountId = accountId;
            }
            else
                state.activeAccountId = null;

            Vue.set(state, 'categories', {});
        },
    },
    actions: {
        createAccount({ dispatch, getters }, { name, accessKey, submittableOrganisation }) {
            return this.$http
                .post(getters.urlAccounts, {
                    name,
                    accessKey,
                    submittableOrganisation,
                })
                .then(resp => {
                    dispatch('loadAccounts', resp.data)
                    return resp.data;
                });
        },
        deleteAccount({ state, commit, dispatch, getters }, { accountId }) {
            return this.$http.delete(`${getters.urlAccounts}/${accountId}`)
                .then(resp => {
                    const deletedAccount = resp.data;
                    commit('deleteAccount', { accountId: deletedAccount._id });

                    // We need to handle if this was the active account
                    if (state.activeAccountId === deletedAccount._id)
                        return dispatch('setActiveAccount', { useFirst: true })
                            .then(() => deletedAccount)

                    return deletedAccount;
                });
        },
        loadAccounts({ commit, dispatch }, accounts) {
            if (_.isArray(accounts)) return accounts.forEach(a => dispatch('loadAccounts', a));

            const project = accounts.projectToFeed;
            if (project && typeof project === 'object') {
                commit('loadProject', project);
                accounts.projectToFeed = project._id;
            }

            return commit('loadAccount', accounts);
        },
        setActiveAccount({ commit, dispatch, getters, state }, { accountId, useFirst }) {
            // Default to no active account if that's where we end up
            let newActiveId = null;

            if (accountId) {
                const account = getters.accounts[accountId];
                // Don't switch if the account isn't active or if 
                // the active account is the one being swtiched to
                if (!account ||
                    !account.active ||
                    accountId === state.activeAccountId) return;

                newActiveId = account._id;
            }
            // If we are setting it to the first available active account
            // and we have active accounts, then use the first
            else if (useFirst && getters.activeAccounts.length)
                newActiveId = getters.activeAccounts[0]._id;

            // Now set loading as we are gonna change the account
            commit('updateStatus', { isLoading: true }, { root: true });

            // TODO: what above sending a message to the server to save the users active account?
            // Perhaps this will just be persisted to the browser for now as users are a long way off
            commit('setActiveAccount', { accountId: newActiveId });

            localStorage.setItem('activeSubmittableAccountId', state.activeAccountId || "");

            return dispatch('submissions/resetAfterAccountChange')
                .then(() => { commit('updateStatus', { isLoading: false }, { root: true }); });
        },
        updateAccount({ state, dispatch, getters }, { accountId, name, active, accessKey }) {
            return this.$http
                .put(`${getters.urlAccounts}/${accountId}`, { name, active, accessKey })
                .then(resp => {
                    let account = resp.data;
                    dispatch('loadAccounts', account)

                    // We need to handle if this was the active account
                    if (state.activeAccountId === account._id && !account.isActive)
                        return dispatch('setActiveAccount', { useFirst: true })
                            .then(() => account)

                    return resp.data;
                });
        },
        reloadAccounts({ commit, dispatch, getters, state, }) {
            commit('removeAccounts', { removeAll: true })
            return this.$http.get(getters.urlAccounts)
                .then((resp) => {
                    dispatch('loadAccounts', resp.data);

                    if (state.activeAccountId) {
                        const activeAccount = getters.accounts[state.activeAccountId];

                        if (!activeAccount || !activeAccount.active)
                            return dispatch('setActiveAccount', { useFirst: true })
                    }
                });
        },
        saveCategoryDetails({ commit, getters, rootState }, { _id }) {
            const cat = rootState.categories[_id];

            if (cat.isNew) {
                return this.$http
                    .post(`${getters.urlBase}/categories`, {
                        submittableCategory: cat.submittableCategory,
                        details: {
                            name: cat.name,
                            colorHex: cat.colorHex
                        }
                    })
                    .then(({ data: savedCat }) => {
                        commit('categories/load', {
                            category: savedCat,
                            oldTemporaryId: cat.submittableCategory.category_id,
                        }, { root: true })
                        return savedCat;
                    });
            }
            else {
                return this.$http
                    .put(`${getters.urlBase}/categories/${_id}`, {
                        name: cat.name,
                        colorHex: cat.colorHex
                    })
                    .then(({ data: savedCat }) => {
                        commit('categories/load', { category: savedCat, }, { root: true });

                        return savedCat;
                    });
            }
        },
        categoriesLoad({ commit, dispatch, getters }) {
            return this.$http.get(`${getters.urlSubmittbleApiAccess}/categories`)
                .then(({ data: { items: categories } }) => {
                    categories.forEach(c => { commit('categoryLoad', c) });
                })
                .catch(err => dispatch('handleSubmittableError', err))
        },
        handleSubmittableError({ dispatch }, reason) {
            const resp = reason.response
            if (resp) {
                // Submittable rejected the access key
                if (resp.status === 401 && resp.data.rejectedKey)
                    return dispatch('reloadAccounts')
                        .then(() => {
                            this.$handleError.rejectWith.routeTo({
                                routeTo: {
                                    name: 'Submittable Accounts',
                                    params: {
                                        rejectedKey: true
                                    },
                                }
                            });
                        })
                // Submittable account doesn't exist in the DB
                else if (resp.status === 404 && resp.data.submittableAccountNotFound)
                    return dispatch('reloadAccounts')
                        .then(() => {
                            this.$handleError.rejectWith.routeTo({
                                routeTo: {
                                    name: 'Submittable Accounts',
                                    params: {
                                        missingAccount: true
                                    },
                                }
                            });
                        })

            }

            // If we weren't able to handle it here, then pass the error along
            throw reason;
        },
    },
}