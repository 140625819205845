//import store from '@/store'
import { roles } from '@/helpers/security'

const adminAccess = [roles.admin]

export default {
    routes: [
        {
            path: 'staff',
            name: 'Staff Admin',
            component: () => import(/* webpackChunkName: "staff-admin" */ '@/modules/admin/views/staff-admin.vue'),
            meta: {
                roles: adminAccess
            },
        }, {
            path: 'reports',
            name: 'Reports',
            component: () => import(/* webpackChunkName: "reports-dashboard" */ '@/modules/admin/views/reports-dashboard.vue'),
            meta: {
                roles: adminAccess
            },
        },
    ],
}