import _ from 'lodash';
import Vue from 'vue';

// Modules
import { store as admin } from '@/modules/admin';
import { store as projects } from '@/modules/projects';
import { store as submittable } from '@/modules/submittable';

export default {
    namespaced: true,
    modules: {
        admin,
        projects,
        submittable,
    },
    state: {
        allOrganisations: {},
        activeId: null
    },
    getters: {
        active: state => state.activeId ? state.allOrganisations[state.activeId] : undefined,
        activeUrl: (state, getters) => getters.active ? `/org/${getters.active._id}` : '/org/noActiveOrganisation',
        activeUrlPrepend: (state, getters) => url => getters.activeUrl + url,
        hasRole: (state, getters) => role => getters.roles.indexOf(role) >= 0,
        hasRoles: (state, getters) => roles => _.isArray(roles)
            ? roles.reduce((hasRoles, role) => hasRoles && getters.hasRoles(role), true)
            : getters.hasRole(roles),
        roles: (state, getters) => getters.active.roles,
    },
    mutations: {
        loadOrganisation(state, { organisation }) {
            Vue.set(state.allOrganisations, organisation._id, organisation);
        },
        setActiveOrganisation(state, { organisationId }) {
            state.activeId = organisationId;
        },
        setActiveOrgnisationOutOfSyncCount(state, count) {
            const active = state.allOrganisations[state.activeId];
            active.outOfSyncCount = count;
        },
    },
    actions: {
        loadFromInitialState({ commit, dispatch, state }, { organisations, activeId }) {
            organisations.forEach(organisation => {
                const accs = organisation.submittableAccounts;
                if (accs) {
                    dispatch('organisation/submittable/loadAccounts', accs, { root: true });
                    organisation.submittableAccounts = accs.map(a => a._id);
                }

                const pjs = organisation.projects;
                if (pjs) {
                    commit('organisation/projects/loadProjects', pjs, { root: true });
                    organisation.projects = pjs.map(p => p._id);
                }

                commit('loadOrganisation', { organisation });
            });

            if (activeId && state.allOrganisations[activeId])
                state.activeId = activeId;
            else if (organisations.length)
                dispatch('setActiveOrganisation', { organisationId: organisations[0]._id })
        },
        setActiveOrganisation({ commit, dispatch, getters }, { organisationId }) {
            commit('setActiveOrganisation', { organisationId });

            const active = getters.active;

            if (active)
                dispatch('organisation/submittable/syncing/switchOrganisation', { count: active.outOfSyncCount, }, { root: true });

            // TODO: Persist when multiple organisation are introduced. With one, this is actually ok
        }
    },
};
