<template>
    <v-select v-model="boundValue"
              :label="field.label"
              :items="items"
              outlined
              :readonly="readonly"
              :disabled="disabled"
              :error-messages="field.validationMessage"
              :hide-details="hideDetails"
              @blur="$emit('blur', $event)"
              @focus="$emit('focus', $event)"></v-select>
</template>
<script>
    export default {
        name: 'field-select',
        props: {
            field: {
                type: Object,
                required: true,
            },
            disabled: Boolean,
            hideDetails: Boolean,
            readonly: Boolean,
            value: [String, Number],
        },
        computed: {
            items() {
                return this.field.options || [this.field.value]; // saved fields don't have options...
            },
            boundValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                },
            },
        },
    };
</script>