import { render, staticRenderFns } from "./field-rich-text.vue?vue&type=template&id=d315fd3c&scoped=true&"
import script from "./field-rich-text.vue?vue&type=script&lang=js&"
export * from "./field-rich-text.vue?vue&type=script&lang=js&"
import style0 from "./field-rich-text.vue?vue&type=style&index=0&id=d315fd3c&scoped=true&lang=css&"
import style1 from "./field-rich-text.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d315fd3c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VInput } from 'vuetify/lib/components/VInput';
installComponents(component, {VCard,VCardSubtitle,VCardText,VInput})
