import _ from 'lodash';
import Vue from 'vue';
import getValidationFunction from '@/helpers/fieldValidationFunctions';
import thresholdIsMetByValue from '@/helpers/thresholdIsMetByValue';
import { sortFieldsByOrder, sortFieldsAllCollatedNotes, sortFieldCollatedNotesValues } from '@/helpers/sorts';

import history from './history';

export default {
    namespaced: true,
    modules: {
        history
    },
    state: {
        tasks: {},
        queue: [],
        savingTasks: {},
    },
    getters: {
        taskModificationsForSubmissionStep: state => taskId => {
            const mods = state.tasks[taskId].modifications;
            return {
                fields: _.keys(mods).map(_id => { return { _id, value: mods[_id] } })
            }
        },
        urlBase: () => 'tasks',
        urlForField: (s, getters) => (taskId, fieldId) => `${getters.urlForTask(taskId)}/fields/${fieldId}`,
        urlForFieldCollatedNotes: (s, getters) => (taskId, fieldId) => `${getters.urlForField(taskId, fieldId)}/collatedNotesUser`,
        urlForFieldCollatedNotesValue: (s, getters) => (taskId, fieldId, valueId) => `${getters.urlForFieldCollatedNotes(taskId, fieldId)}/${valueId}`,
        urlForFile: (s, getters) => (taskId, fileId) => `${getters.urlForTask(taskId)}/files/${fileId}`,
        urlForTask: (s, getters) => taskId => `${getters.urlBase}/${taskId}`,
    },
    mutations: {
        collatedNotesUserValueAdd(state, { taskId, fieldId, userValue }) {
            const task = state.tasks[taskId];
            if (!task) return;
            const field = task.fields[fieldId];
            if (!field || !field.value || !(field.value instanceof Array)) return;
            field.value.push(userValue);

            // Resort the collated notes tasks
            sortFieldCollatedNotesValues(field);
        },
        collatedNotesUserValueRemove(state, { taskId, fieldId, userValueId }) {
            const task = state.tasks[taskId];
            if (!task) return;
            const field = task.fields[fieldId];
            if (!field || !field.value || !(field.value instanceof Array)) return;
            const userValue = field.value.find(v => v._id === userValueId);
            if (!userValueId) return;
            Vue.delete(field.value, field.value.indexOf(userValue));
        },
        loadTask(state, { task: taskToLoad, processedTaskId }) {
            const existing = state.tasks[taskToLoad._id];

            // If this is the same task, then we don't need to reload.
            // The user's changes are the best version
            if (existing && existing.name === taskToLoad.name) return;

            const task = { ...taskToLoad };
            task.modifications = {};

            // remap the fields into an object and add calculation of available fields
            if (Array.isArray(task.fields)) {
                // Let's start by organising the collated notes fields
                const fields = sortFieldsAllCollatedNotes(task.fields);

                const newFields = task.fields = {};
                fields.forEach(f => newFields[f._id] = f);

                // We will calculate the available fields in the store too
                task._fieldsAvailable = [];
                Object.defineProperty(task, 'fieldsAvailable', {
                    get: function () {
                        const fields = this._fieldsAvailable.map(
                            fieldId => this.fields[fieldId]
                        )

                        return fields.sort(sortFieldsByOrder(fields));
                    }
                });

                // This may be a little sloppy, but keep track of which fields are
                // determining the available fields, so that we can skip recalculations
                // when changes are made to other fields (e.g. when text is being typed)
                const fd = task.fieldsDeterminingAvailable = {};
                fields.forEach(f => {
                    if (f.threshold) fd[f.threshold.fieldId] = true;

                    // While we are here, we might as well add a validation function
                    f.isValid = getValidationFunction(f.type);
                });

                // Add the method for determining available fields
                (task.determineAvailableFields = function (modifiedFieldId) {
                    // if this is being calculated due to a field modification and that field
                    // does not determine available fields, then we can skip calculation
                    if (modifiedFieldId && !fd[modifiedFieldId]) return;

                    const fields = task.fields;
                    const fa = task._fieldsAvailable;
                    // empty the avaialble fields
                    fa.length = 0;

                    _.forOwn(fields, f => {
                        if (f.threshold) {
                            const val = fields[f.threshold.fieldId].value;
                            if (!thresholdIsMetByValue(f.threshold, val))
                                return;
                        }

                        // If the field is for a particular outcome, only include it if that is selected
                        if (f.forOutcomeChoiceId && (!task.selectedOutcome || f.forOutcomeChoiceId !== task.selectedOutcome._id))
                            return;

                        fa.push(f._id);
                    });

                    return fa;
                })();
            }

            if (Array.isArray(task.previousStepDetails)) {
                task.previousStepDetails.forEach(psd => {
                    if (Array.isArray(psd.fields))
                        psd.field = sortFieldsAllCollatedNotes(psd.fields);
                })
            }

            // Add a method for selecting a tasks outcome
            task.selectOutcomeById = function (id) {
                const { outcomes } = this;
                const { type } = outcomes;
                return type === 'Thresholded'
                    ? outcomes.overrides.find(o => o._id === id)
                    : type === 'Multiple Choice'
                        ? outcomes.choices.find(c => c._id === id)
                        : 'Outcome selection not possible';
            }

            // We need to keep track of whether the task was flagged by another user
            if (task.flaggedWarning)
                task.initialFlaggedWarning = task.flaggedWarning;

            Vue.set(state.tasks, task._id, task);

            const newQueueItem = {
                _id: task._id,
                submissionId: task.submissionId,
                type: task.type,
                status: task.status,
            };

            // If we are adding a new task that is the result of a previous task processing.
            // Then we want to put it in the same place in the queue
            if (processedTaskId) {
                // Manage the queue
                // NB: This may introduce a bug at some point, particularly if we want to display
                // two tasks for a particular submission
                const queueItem = state.queue.find(t => t._id === processedTaskId);

                if (queueItem) {
                    state.queue.splice(state.queue.indexOf(queueItem) + 1, 0, newQueueItem)
                    return;
                }
            }

            state.queue.push(newQueueItem);
        },
        removeTask(state, { taskId }) {
            const queueItem = state.queue.find(t => t._id === taskId)
            if (queueItem) Vue.delete(state.queue, state.queue.indexOf(queueItem));
            Vue.delete(state.tasks, taskId);
        },
        selectTaskOutcome(state, { taskId, selectedOutcomeId }) {
            const task = state.tasks[taskId];
            Vue.set(task, 'completeSelectedOutcome', false);

            let outcome;
            const startingFields = (task._fieldsAvailable || []).slice();

            if (selectedOutcomeId)
                outcome = task.selectOutcomeById(selectedOutcomeId);


            Vue.set(task, 'selectedOutcome', outcome);
            task.determineAvailableFields();

            // If the fields have changed, then we they must be completed before trying to process the task
            if (selectedOutcomeId && task._fieldsAvailable.reduce(
                (hasChange, id) =>
                    hasChange || startingFields.indexOf(id) === -1,
                false
            )) {
                Vue.set(task, 'completeSelectedOutcome', true);
            }
        },
        setCollatedNotesTaskValue(state, { taskId, value }) {
            const task = state.tasks[taskId];
            Vue.set(task.modifications, 'value', value);
        },
        setConfirmTask(state, { taskId, isConfirming }) {
            const task = state.tasks[taskId];
            Vue.set(task, 'confirmTask', !!isConfirming);
        },
        setFieldValue(state, { taskId, fieldId, value }) {
            const task = state.tasks[taskId];
            const field = task.fields[fieldId];
            Vue.set(field, 'value', value);
            Vue.set(task.modifications, field._id, value);
            task.determineAvailableFields(fieldId);
        },
        setFlaggedWarning(state, { taskId, isFlagged }) {
            const task = state.tasks[taskId];
            Vue.set(task, 'flaggedWarning', task.initialFlaggedWarning || isFlagged);
        },
        setTaskStatus(state, { taskId, status }) {
            const task = state.tasks[taskId];
            Vue.set(task, 'status', status);

            const queueItem = state.queue.find(t => t._id === taskId);
            if (queueItem)
                Vue.set(queueItem, 'status', status);

        },
        taskIsSaving(state, { taskId, isSaving }) {
            Vue.set(state.savingTasks, taskId, !!isSaving)
        },
        taskModificationsCleanup(state, { taskId, savedModifications }) {
            const task = state.tasks[taskId];

            if (savedModifications.fields instanceof Array)
                savedModifications.fields.forEach(field => {
                    if (task.modifications[field._id] === field.value)
                        Vue.delete(task.modifications, field._id);
                });

            if (task.type === 'Collect Collated Notes') {
                if (savedModifications.value || savedModifications.value === '')
                    task.fieldValue = savedModifications.value;

                if (savedModifications.value === task.modifications.value)
                    Vue.delete(task.modifications, 'value');
            }

        },
        validateTask(state, { taskId, valueRequired }) {
            const task = state.tasks[taskId];

            // validate the fields that are currently avaialble on the task (if there any);
            const fieldsValid = !task._fieldsAvailable
                || task.fieldsAvailable.reduce(
                    (allValid, field) => field.isValid(valueRequired) && allValid,
                    true
                );

            Vue.set(task, 'valid', fieldsValid);
        },
    },
    actions: {
        collatedNotesFinalise({ commit, dispatch, getters, state }, { taskId }) {
            const task = state.tasks[taskId];
            if (task.fieldRequired && ((task.modifications.value || task.fieldValue) == '')) return;

            const body = { modifications: task.modifications };
            return this.$http
                .post(getters.urlForTask(taskId), body)
                .then(() => {
                    commit('taskModificationsCleanup', {
                        taskId,
                        savedModifications: body.modifications
                    });
                    return dispatch('history/createFromCompletedTask', { taskId })
                })
                .then(() => {
                    commit('removeTask', { taskId });
                    commit('setSnackBackDetails', { text: `Your notes for <em>${task.title}</em> have been submitted.`, isShown: true }, { root: true });
                });
        },
        collatedNotesEditorAdd({ commit, getters }, { taskId, fieldId, userId }) {
            return this.$http.post(getters.urlForFieldCollatedNotes(taskId, fieldId), { userId })
                .then(({ data: userValue }) => {
                    commit('collatedNotesUserValueAdd', { taskId, fieldId, userValue });
                });
        },
        collatedNotesEditorRemove({ commit, getters }, { taskId, fieldId, userValueId }) {
            return this.$http.delete(getters.urlForFieldCollatedNotesValue(taskId, fieldId, userValueId))
                .then(() => {
                    commit('collatedNotesUserValueRemove', { taskId, fieldId, userValueId });
                });
        },
        dismissTask({ commit, getters }, { taskId }) {
            return this.$http.delete(getters.urlForTask(taskId))
                .then(() => { commit('removeTask', { taskId }) })
        },
        downloadSubmissionFile({ commit, getters, state }, { taskId, fileId }) {
            const task = state.tasks[taskId];
            const file = task.files.find(f => f._id === fileId);
            commit('files/setDownloading', { fileId, isDownloading: true }, { root: true, });
            return this.$http
                .get(getters.urlForFile(taskId, fileId), { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: response.headers['content-type']
                    })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = file.name;
                    link.click()

                    commit('files/setDownloading', { fileId, isDownloading: false }, { root: true, });
                });
        },
        loadTasks({ commit, dispatch }, { tasks, processedTaskId }) {
            if (Array.isArray(tasks))
                return tasks.forEach(t => { dispatch('loadTasks', { tasks: t, processedTaskId }) });

            commit('loadTask', { task: tasks, processedTaskId });

            if (tasks.savedOutcomeId)
                commit('selectTaskOutcome', { taskId: tasks._id, selectedOutcomeId: tasks.savedOutcomeId });

            commit('validateTask', { taskId: tasks._id });
        },
        processStep({ commit, dispatch, getters, state }, { taskId }) {
            commit('validateTask', { taskId, valueRequired: true });
            const task = state.tasks[taskId];

            if (!task.valid && task.status !== 'Withdrawn') return Promise.resolve('Task Invalid');

            let selectedOutcomeId = task.selectedOutcome ? task.selectedOutcome._id : null; // undefined doesn't modify, null unsets

            return this.$http
                .post(getters.urlForTask(taskId), {
                    selectedOutcomeId,
                    modifications: getters.taskModificationsForSubmissionStep(taskId),
                })
                .then(({ data: newTask }) => dispatch('history/createFromCompletedTask', { taskId })
                    .then(() => {
                        if (newTask) dispatch('loadTasks', { tasks: newTask, processedTaskId: taskId });
                        commit('removeTask', { taskId });
                        commit('setSnackBackDetails', { text: `<em>${task.title}</em> has been submitted.`, isShown: true }, { root: true });
                    })
                );
        },
        reload({ commit, dispatch, getters, state }) {
            return this.$http
                .get(getters.urlBase)
                .then(({ data: tasks }) => {
                    // Clear out any tasks that don't exist
                    // Perhaps this is unecessary, but it seems better than errors?
                    _.forOwn(state.tasks, (t, taskId) => {
                        if (!tasks.find(t => t._id === taskId))
                            commit('removeTask', { taskId })
                    })

                    return dispatch('loadTasks', { tasks })
                });
        },
        save({ commit, getters, state }, { taskId, isAutosave }) {
            const task = state.tasks[taskId];

            if (task.type !== 'Submission Step' && task.type !== 'Collect Collated Notes') return;

            let body = {};

            if (task.type === 'Submission Step') {
                body.selectedOutcomeId = task.selectedOutcome ? task.selectedOutcome._id : null; // undefined doesn't modify, null unsets

                body.modifications = {
                    ...getters.taskModificationsForSubmissionStep(taskId)
                };
            }
            else
                body.modifications = task.modifications;

            commit('taskIsSaving', { taskId, isSaving: true });
            return this.$http
                .put(getters.urlForTask(taskId), body)
                .then(() => {
                    commit('taskIsSaving', { taskId, isSaving: false });
                    if (!isAutosave)
                        commit('setSnackBackDetails', { text: `Your changes to <em>${task.title}</em> have been saved.`, isShown: true }, { root: true });

                    commit('taskModificationsCleanup', {
                        taskId,
                        savedModifications: body.modifications
                    });
                })
                .catch(err => {
                    commit('taskIsSaving', { taskId, isSaving: false });
                    if (err.response.status === 409 && err.response.data.withdrawn)
                        commit('setTaskStatus', { taskId, status: 'Withdrawn' });
                    else
                        throw err;
                });
        },
        outcomeCancel({ commit, dispatch }, { taskId }) {
            commit('selectTaskOutcome', { taskId });
            dispatch('save', { taskId, isAutosave: true });
        },
        outcomeSelect({ commit, dispatch, state }, { taskId, selectedOutcomeId }) {
            const task = state.tasks[taskId];

            // If the task has not had this outcome selected then select it
            // and determine if that affects which fields need to be filled in
            if (
                selectedOutcomeId &&
                (!task.selectedOutcome ||
                    task.selectedOutcome._id !== selectedOutcomeId)
            ) {
                commit('selectTaskOutcome', {
                    taskId: taskId,
                    selectedOutcomeId,
                });
                dispatch('save', { taskId, isAutosave: true });

                if (task.completeSelectedOutcome) return;
            }

            commit('validateTask', {
                taskId: taskId,
                valueRequired: true,
            });

            if (task.valid) commit('setConfirmTask', { taskId, isConfirming: true });
        },
        setFieldValue({ commit }, { taskId, fieldId, value }) {
            commit('setFieldValue', { taskId, fieldId, value });
            commit('validateTask', { taskId });
        },
    },
}