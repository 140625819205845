<template>
    <router-view v-if="initialised || errored"></router-view>
    <transition name="fade"
                v-else>
        <div class="booting-up v-application">
            <div>
                <img src="/img/logo.png"
                     height="196" />

                <v-progress-linear class="mt-3"
                                   color="amber darken-2"
                                   height="10"
                                   rounded
                                   indeterminate
                                   striped></v-progress-linear>
            </div>
        </div>
    </transition>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'App',
        computed: {
            ...mapState(['errored', 'initialised']),
        },
    };
</script>
<style scoped>
    .booting-up {
        display: flex;
        height: 100vh;
        width: 100vw;
        justify-content: center;
        align-items: center;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>