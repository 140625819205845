<template>
    <field-base :value="field.value"
                @input="setValue(field._id, $event)"
                @submission-flagged-warning="flagSubmission"
                :field="field"
                :disabled="disabled"
                @focus="autoSaveIntervalStart"
                @blur="autoSaveIntervalEnd"></field-base>
</template>
<script>
    import fieldBase from '@/components/field-base';

    export default {
        name: 'submission-field',
        components: {
            fieldBase,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
            field: {
                type: Object,
                required: true,
            },
            disabled: Boolean,
        },
        data() {
            return {
                autoSaveInterval: undefined,
                startingValue: this.field.value,
            };
        },
        methods: {
            autoSave() {
                if (this.startingValue !== this.field.value)
                    this.$store.dispatch('myTasks/save', {
                        taskId: this.taskId,
                        isAutosave: true,
                    });

                this.startingValue = this.field.value;
            },
            autoSaveIntervalEnd() {
                this.autoSave();
                if (this.autoSaveInterval) clearInterval(this.autoSaveInterval);
                this.autoSaveInterval = undefined;
            },
            autoSaveIntervalStart() {
                this.startingValue = this.field.value;
                this.autoSaveInterval = setInterval(() => {
                    this.autoSave();
                }, 60000);
            },
            flagSubmission(isFlagged) {
                this.$store.commit('myTasks/setFlaggedWarning', {
                    taskId: this.taskId,
                    isFlagged,
                });
            },
            setValue(fieldId, value) {
                this.$store.dispatch('myTasks/setFieldValue', {
                    taskId: this.taskId,
                    fieldId,
                    value,
                });
            },
        },
        beforeDestroy() {
            if (this.autoSaveInterval) this.autoSaveIntervalEnd();
        },
    };
</script>