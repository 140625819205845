let textArea;

/**
 * 
 * @param {String} value - the value to copy to the clipboard (ignored if an existing element is passed in - the value of the element will be used)
 * @param {HTMLElement}  elToEndFocussed - an element to leave focussed at the end of the copy
 * @param {HTMLElement} existingElement - an element to copy from if we have one
 */
export default function (value, elToEndFocussed = undefined, existingElement = undefined) {
    if (!textArea && !existingElement) {
        textArea = document.createElement('textarea');
        textArea.id = 'copy-to-clipboard-helper';
        textArea.style.visability = 'hidden';
        textArea.style.position = 'absolute';
    }

    if (!existingElement) {
        document.body.appendChild(textArea);
        textArea.value = value;
        textArea.select();
    }
    else
        existingElement.select();

    document.execCommand('copy');

    if (window.getSelection)
        window.getSelection().removeAllRanges();

    if (!existingElement)
        document.body.removeChild(textArea);

    if (elToEndFocussed && elToEndFocussed.focus)
        elToEndFocussed.focus();
}