<template>
    <v-list-item two-line>
        <v-list-item-avatar color="blue lighten-3"
                            class="white--text">
            <span class="user-initials">{{user.firstName.substr(0,1) + user.surname.substr(0,1)}}</span>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{user.firstName}} {{user.surname}}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState(['user']),
        },
    };
</script>
<style>
    .user-initials {
        font-size: 1.4em;
        font-weight: 300;
    }
</style>