import Vue from 'vue';
import _ from 'lodash';
import { sortText } from '@/helpers/sorts';

import display from './assigningDisplay';
import worktrayDrafts from './assigningWorktrayDrafts';
import locks from './assigningLocks';

const newTracker = (includeExisting, existingAssignments = []) => ({
    draftAssignments: [],
    existingAssignments,

    /** The total subs, which only includes existing if the user has opted for that  */
    get totalWorking() {
        return this.draftAssignments.length + (includeExisting ? this.existingAssignments.length : 0);
    },

    /** The total of the assigned and unassigned subs */
    get totalOverall() {
        return this.draftAssignments.length + this.existingAssignments.length;
    },

    totalIncludesExisting: includeExisting,
});

export default {
    namespaced: true,
    modules: {
        display,
        worktrayDrafts,
        locks,
    },
    state: {
        assigned: {},
        assignedOrder: [],
        confirmAssignments: false,
        method: 'leastSubs',
        staffAssignable: {},
    },
    getters: {
        baseUrl: (s, g, rs, rootGetters) => rootGetters['organisation/projects/activeProjectWorktrays/urlBase'],
        assignedSubmissionIds: state => Object.keys(state.assigned),
        nextSubmission: (s, getters) => {
            const next = getters.submissionList[0];
            return next ? getters.getSubmission(next._id) : undefined;
        },
        getSubmission: (s, g, rs, rootGetters) => id => rootGetters['organisation/projects/activeProjectWorktrays/submissionById'](id),
        staffAllAssignableByName: state => Object.values(state.staffAssignable).sort(sortText('firstName')),
        staffAssignableForActiveWorktray: (s, getters) => getters.worktrayActive ? getters.staffAssignableForRoles(getters.worktrayActive.userRoles) : [],
        staffAssignableForRoles: state => roleList => Object.values(state.staffAssignable)
            .filter(s => (roleList || []).reduce((hasAll, role) => hasAll && s.roles.indexOf(role) > -1, true)),
        staffAssignments: ({ assigned, display: { staffShowExistingSubs } }, getters) => _.transform(
            assigned,
            (tracker, { assigneeId, categoryId, submissionId }) => {
                let staffer = tracker[assigneeId];

                let cat = staffer[categoryId];
                if (!cat) cat = staffer[categoryId] = newTracker(staffShowExistingSubs);

                cat.draftAssignments.push(submissionId);
                return tracker;
            },
            getters.staffExistingAssignments
                .reduce((tracker, staffer) => ({
                    ...tracker,
                    [staffer._id]: {
                        ...Object.keys(staffer)
                            .reduce((all, categoryId) => categoryId === '_id'
                                ? all
                                : ({
                                    ...all,
                                    [categoryId]: newTracker(staffShowExistingSubs, [...staffer[categoryId] || []])
                                }), {}),
                        get total() {
                            return _.keys(this).reduce((total, key) => key === 'total' ? total : total + this[key].totalWorking, 0)
                        },
                    }
                }), {})
        ),
        staffExistingAssignments: (s, getters) => getters.staffAssignableForActiveWorktray.map(s => ({
            _id: s._id,
            ...s.assignedSubs.reduce((tracked, sub) => {
                // We are only interested in tasks from this category
                if (sub.createdFrom.worktrayId === getters.worktrayActiveId) {
                    let cat = tracked[sub.categoryId];
                    if (!cat) cat = tracked[sub.categoryId] = [];
                    cat.push(sub.submissionId);
                }
                return tracked;
            }, {})
        })),
        staffLastAssignedDates: (state, { worktrayActive: { lastAssignedDates } }) => _.transform(
            state.assigned,
            (assignedDates, { assigneeId, reasonId, workingAssignTime }) => {
                const staffAssignedDates = (assignedDates[assigneeId] = assignedDates[assigneeId] || {});
                const currentDate = staffAssignedDates[reasonId];
                staffAssignedDates[reasonId] = currentDate > workingAssignTime ? currentDate : workingAssignTime;
            },
            // Clone the last assigned dates, so that we aren't modifying their state
            JSON.parse(JSON.stringify(lastAssignedDates || {}))
        ),
        staffFilterLockedToEnd: ({ display: staffLockedSortAtEnd }, { staffLockedForActiveWorktray }) => (sortFn, override) => (a, b) => {
            if (!override === undefined ? staffLockedSortAtEnd : override) return sortFn(a, b);
            const aIsLocked = !!staffLockedForActiveWorktray[a._id];
            const bIsLocked = !!staffLockedForActiveWorktray[b._id];
            return aIsLocked === bIsLocked
                ? sortFn(a, b)
                : aIsLocked ? 1 : -1;
        },
        staffFilterForLockedAndWithTotals: (s, { staffAssignableForActiveWorktray, staffAssignments, staffLockedForActiveWorktray }) => hideLocked =>
            (
                hideLocked
                    ? staffAssignableForActiveWorktray.filter(s => !staffLockedForActiveWorktray[s._id])
                    : staffAssignableForActiveWorktray
            )
                .map(s => {
                    const assigned = staffAssignments[s._id] || {
                        total: 0,
                    };
                    return {
                        ...s,
                        assigned,
                    };
                }),
        staffOrderByName: (s, getters) => (asc = true, hideLocked = false, overrideLockedToEnd = undefined) =>
            getters.staffFilterForLockedAndWithTotals(hideLocked).sort(getters.staffFilterLockedToEnd(sortText('firstName', !asc), overrideLockedToEnd)),
        staffOrderForReason: ({ display: { staffLockedHide, staffSortAscending, staffSort } }, getters) => reasonId => {
            // If not by count, then by name
            if (staffSort === 'name') {
                return getters.staffOrderByName(staffSortAscending, staffLockedHide)
            }
            else if (staffSort === 'lastAssigned') {
                const lastAssigned = getters.staffLastAssignedDates;
                const getLastAssignedDate = staffId => new Date((lastAssigned[staffId] || {})[reasonId] || 0).setHours(0, 0, 0, 0);

                return getters.staffFilterForLockedAndWithTotals(staffLockedHide)
                    .sort(getters.staffFilterLockedToEnd(
                        (a, b) => (staffSortAscending ? 1 : -1) *
                            (
                                // Try calculating by assigned date
                                (getLastAssignedDate(a._id) - getLastAssignedDate(b._id)) ||
                                // if they have the same date (only try if they have no date) then sort by count
                                (a.assigned.total - b.assigned.total)
                            )
                    ));
            }
            else {
                return getters.staffFilterForLockedAndWithTotals(staffLockedHide)
                    .sort(getters.staffFilterLockedToEnd((a, b) => (staffSortAscending ? 1 : -1) * (a.assigned.total - b.assigned.total)));
            }
        },
        staffOrderedForNextSubmission: (s, getters) => getters.staffOrderedAndAssignableForAllReasons[getters.nextSubmission ? getters.nextSubmission.reasonId : 1],
        staffOrderedAndAssignableForAllReasons: (state, getters) => getters.worktrayActive.reasons
            .reduce((orderedStaff, reason) => {
                orderedStaff[reason._id] = state.display.submissionsStaffHideLocked
                    ? getters.staffOrderForReason(reason._id).filter(s => !getters.staffLockedForActiveWorktray[s._id])
                    : getters.staffOrderForReason(reason._id);

                return orderedStaff
            }, {}),
        // This is the list of staff that appear against a submission
        staffOrderedAndAssignableForReason: (s, getters) => reasonId => getters.staffOrderedAndAssignableForAllReasons[reasonId],
        stafferWasAlreadyAssignedInThisWorktray: (s, getters) => (stafferId, submission) => {
            const worktray = getters.worktrayActive;
            if (worktray.rules.allowReassign) return false;
            const existingAssigns = submission._existingAssigns[worktray._id];
            return !!(existingAssigns && existingAssigns[stafferId]);
        },
        submissionList: (state, getters) => {
            const assignedIds = getters.assignedSubmissionIds;
            const {
                includeUnassigned,
                includeAssigned,
                categoriesToExclude,
                submissionsSort,
                submissionsSortAscending,
            } = state.display;

            return getters.submissions
                .filter(s => {
                    const isAssigned = assignedIds.indexOf(s._id) > -1;
                    return s.title && // exclude submissions that haven't been fetched yet
                        (includeAssigned && isAssigned
                            || includeUnassigned && !isAssigned) &&
                        !categoriesToExclude[s.category._id];
                })
                .sort(
                    submissionsSort === 'category'
                        ? sortText('category.name', !submissionsSortAscending)
                        : submissionsSort === 'reason'
                            ? (a, b) =>
                                (a.reasonId !== b.reasonId
                                    // Sort by reason if they are different
                                    ? (submissionsSortAscending ? 1 : -1) * (a.reasonId - b.reasonId)
                                    // Otherwise, if it is the same reason, sort the submission date to the top
                                    : new Date(a.createdDate) - new Date(b.createdDate)
                                )
                            // Default is to sort by assigning date
                            : (a, b) => (submissionsSortAscending ? 1 : -1) * (new Date(a.createdDate) - new Date(b.createdDate))
                );
        },
        submissions: (s, getters) => getters.worktrayActive.type !== 'Assigning'
            ? []
            : getters.worktrayActive.items.map(s => { return { ...s, ...getters.getSubmission(s._id) } }),
        submissionsToAssignCount: (state, getters) => getters.submissions.length - getters.assignedSubmissionIds.length,
        worktrayActive: (s, g, rootState) => rootState.organisation.projects.activeProjectWorktrays.activeWorktray,
        worktrayActiveAssignments: (state, getters) => getters.submissions
            .reduce((assigned, s) => {
                const assignedDetails = state.assigned[s._id];
                if (assignedDetails) assigned[s._id] = assignedDetails;
                return assigned;
            }, {}),
        worktrayActiveId: (s, getters) => getters.worktrayActive._id,
        worktrayActiveReasons: (s, getters) => getters.worktrayActive.reasons.reduce((reasons, reason) => { reasons[reason._id] = reason; return reasons }, {}),
        worktrayCategories: (s, getters) => getters.submissions
            .map(s => s.category ? s.category._id : undefined)
            .filter((catId, index, self) => catId !== undefined && self.indexOf(catId) === index),
        worktrayCategoriesToInclude: (state, getters) => getters.worktrayCategories.filter(_id => !state.display.categoriesToExclude[_id]),
    },
    mutations: {
        assign(state, { assigneeId, submissionId, categoryId, reasonId }) {
            Vue.set(state.assigned, submissionId, {
                submissionId,
                assigneeId,
                categoryId,
                reasonId,
                workingAssignTime: Date.now(),
            });

            state.assignedOrder.push(submissionId);
        },
        setAssigningMethod(state, { method }) {
            if (method) state.method = method;
        },
        setConfirmAssignments(state, confirmAssignments) {
            state.confirmAssignments = confirmAssignments;
        },
        staffAssignableLoad(state, { staff, forceReload }) {
            const addStaff = staffer => {
                if (!state.staffAssignable[staffer._id] || forceReload)
                    state.staffAssignable[staffer._id] = staffer
            }

            if (Array.isArray)
                staff.forEach(addStaff);
            else
                addStaff(staff)
        },
        staffLoadSubmission(state, { staffId, submission }) {
            const staffer = state.staffAssignable[staffId];
            if (staffer)
                staffer.assignedSubs.push(submission);
        },
        submissionHasBeenAssigned(state, { submissionId }) {
            Vue.delete(state.assignedOrder, state.assignedOrder.indexOf(submissionId));
            Vue.delete(state.assigned, submissionId);
        },
        unassign(state, { submissionId }) {
            Vue.delete(state.assignedOrder, state.assignedOrder.indexOf(submissionId));
            Vue.delete(state.assigned, submissionId);
        },
        clearHistory(state) {
            Vue.set(state, 'assignedOrder', []);
        }
    },
    actions: {
        assign({ commit, getters }, { assigneeId, submissionId }) {
            const sub = getters.getSubmission(submissionId);
            if (sub) commit('assign', { assigneeId, submissionId, categoryId: sub.category._id, reasonId: sub.reasonId });
        },
        assignNext({ dispatch, getters }, { assigneeId }) {
            const sub = getters.nextSubmission;
            if (sub) dispatch('assign', { assigneeId, submissionId: sub._id });
        },
        process({ commit, dispatch, getters }) {
            const allAssigned = Object.values(getters.worktrayActiveAssignments);

            const startTime = new Date();
            const worktrayId = getters.worktrayActiveId;

            return this.$http.post(`${getters.baseUrl}/assignMany`, allAssigned)
                .then(({ data }) => {
                    return dispatch('organisation/projects/activeProjectLoad', data.project, { root: true })
                        .then(() => data)
                })
                .then(data => {
                    dispatch('organisation/projects/activeProjectWorktrays/activeWorktrayLoad', { worktray: data.worktray }, { root: true })

                    if (Array.isArray(data.submissions))
                        data.submissions.forEach(sub => {
                            const subIdObj = { submissionId: sub._id };
                            commit('submissionHasBeenAssigned', subIdObj);
                            commit('organisation/projects/activeProjectWorktrays/submissionRemove', subIdObj, { root: true });

                            const stepCreatedHere = sub.steps.find(step =>
                                step.state === 'Active' &&
                                new Date(step.createdDate) > startTime &&
                                step.createdFrom.worktrayId === worktrayId
                            );

                            if (stepCreatedHere)
                                commit('staffLoadSubmission', {
                                    staffId: stepCreatedHere.assignee._id,
                                    // Matches query in Project.getAssignableStaff() => tasks.group
                                    submission: {
                                        categoryId: sub.category._id,
                                        createdFrom: stepCreatedHere.createdFrom,
                                        projectStepId: stepCreatedHere.projectStepId,
                                        submissionId: sub._id,
                                    }
                                });
                        });
                    dispatch('clearActiveWorktraysDraft');
                    dispatch('resetActiveWorktrayLocks');

                });
        },
        undoLastAssign({ commit, state, getters }) {
            const ao = state.assignedOrder;
            const lastSubmissionId = ao[ao.length - 1];
            if (!lastSubmissionId) return undefined;

            const assigned = state.assigned[lastSubmissionId];
            if (!getters.staffLockedForActiveWorktray[assigned.assigneeId])
                commit('unassign', { submissionId: lastSubmissionId });
            else {
                const subTitle = getters.getSubmission(lastSubmissionId).title;
                const assigneeName = getters.staffAssignableForActiveWorktray.find(s => s._id === assigned.assigneeId).firstName

                commit('setSnackBackDetails', {
                    isShown: true,
                    text: `Cannot unassign '<em>${subTitle}</em>' from ${assigneeName} as ${assigneeName} is locked.`
                }, { root: true })
            }
        },
    }
};