export default (store, to, from, next, skipForceReload = false) => store.dispatch('organisation/projects/changeActiveProject', {
    projectId: to.params.projectId || to.params.id,
    forceReload: skipForceReload
        ? false
        : from.path.indexOf(`/projects/${to.params.id}`) === -1
})
    .then(exists => {
        if (exists)
            next();
        else
            next('/404');
    })
    .catch(next);