<template>
    <v-checkbox v-model="boundValue"
                :label="label"
                :readonly="readonly"
                :disabled="disabled"
                :error-messages="field.validationMessage"
                :hide-details="hideDetails"
                :on-icon="field.selectedIcon"
                :off-icon="field.notSelectedIcon"
                :color="field.selectedIconColor"
                :background-color="bgColor"
                @blur="$emit('blur', $event)"
                @focus="$emit('focus', $event)"
                outlined
                class="pt-0 mt-0 round-corners"></v-checkbox>
</template>
<script>
    export default {
        name: 'field-select',
        props: {
            field: {
                type: Object,
                required: true,
            },
            disabled: Boolean,
            hideDetails: Boolean,
            readonly: Boolean,
            value: Boolean,
        },
        computed: {
            bgColor() {
                return this.field[
                    !this.value ? 'notSelectedBgColor' : 'selectedBgColor'
                ];
            },
            boundValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                    this.$emit('submission-flagged-warning', newValue);
                },
            },
            label() {
                const { label, selectedLabel } = this.field;
                return !this.value ? label : selectedLabel || label;
            },
        },
    };
</script>
<style>
    .round-corners .v-input__slot {
        border-radius: 5px;
        padding: 2px;
    }
</style>