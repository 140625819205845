<template>
    <card-actions>
        <v-btn v-for="choice in outcomes.choices"
               :key="choice._id"
               @click="selectChoice(choice._id)"
               color="primary"
               text
               :loading="loading && chosenId === choice._id"
               :disabled="disabled || loading && chosenId !== choice._id">{{choice.name}}</v-btn>
        <template #secondary>
            <save-task-btn :task-id="taskId"></save-task-btn>
        </template>
    </card-actions>
</template>
<script>
    import saveTaskBtn from './save-task-btn';

    export default {
        name: 'outcome-actions-multiple-choice',
        components: {
            saveTaskBtn,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
            outcomes: {
                type: Object,
                required: true,
            },
            loading: Boolean,
            disabled: Boolean,
        },
        data() {
            return {
                chosenId: null,
            };
        },
        methods: {
            selectChoice(choiceId) {
                this.chosenId = choiceId;
                this.$emit('select-outcome', choiceId);
            },
        },
    };
</script>