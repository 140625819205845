<template>
    <div class="d-flex">
        <submission-flag v-if="submission.flaggedWarning"></submission-flag>
        <slot name="pre-category"></slot>
        <category-chip-editable v-if="editableCategory"
                                :category-id="submission.category._id"
                                :small="small"></category-chip-editable>
        <category-chip v-else
                       :category-id="submission.category._id"
                       :small="small"></category-chip>
        <slot name="post-category"></slot>
        <status-indicator v-if="showStatus"
                          :status="submission.status"
                          class="ml-2"
                          :small="small"></status-indicator>
    </div>
</template>
<script>
    import categoryChip from './category-chip.vue';
    import categoryChipEditable from './category-chip-editable.vue';
    import statusIndicator from './status-indicator.vue';
    import submissionFlag from './submission-flag.vue';

    export default {
        name: 'submisison-indicators',
        components: {
            categoryChip,
            categoryChipEditable,
            statusIndicator,
            submissionFlag,
        },
        props: {
            submission: {
                type: Object,
                required: true,
            },
            editableCategory: Boolean,
            showStatus: Boolean,
            small: Boolean,
        },
    };
</script>