export default {
    routes: [
        {
            path: '/',
            name: 'My Tasks',
            component: () => import(/* webpackChunkName: "task-list" */ '@/modules/my-tasks/views/task-list.vue'),

        },
        {
            path: 'history',
            name: 'Editor History',
            component: () => import(/* webpackChunkName: "editor-history" */ '@/modules/my-tasks/views/editor-history.vue'),
        },
    ],
}