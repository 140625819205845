<template>
    <v-select v-model="boundValue"
              :label="field.label"
              :items="users"
              :item-text="user => `${user.firstName} ${user.surname}`"
              item-value="_id"
              outlined
              :readonly="readonly"
              :disabled="disabled"
              :error-messages="field.validationMessage"
              :hide-details="hideDetails"
              @blur="$emit('blur', $event)"
              @focus="$emit('focus', $event)"></v-select>
</template>
<script>
    import { sortText } from '@/helpers/sorts';

    export default {
        name: 'field-user-select',
        props: {
            field: {
                type: Object,
                required: true,
            },
            disabled: Boolean,
            hideDetails: Boolean,
            readonly: Boolean,
            value: [String, Object],
        },
        computed: {
            boundValue: {
                get() {
                    return typeof this.value === 'object'
                        ? this.value._id
                        : this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                },
            },
            users() {
                const users =
                    this.field.options ||
                    // saved fields don't have options...
                    (this.field.value ? [this.field.value] : []);

                return users.slice().sort(sortText('firstName'));
            },
        },
    };
</script>