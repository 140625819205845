<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="min-width">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="`Submission Withdrawn: ${task.title} (${task.notificationReason || task.stepName})`"
                         class="text-truncate pr-2">
                        <span class="mr-2">Submission Withdrawn:</span><em>{{task.title}} ({{taskName}})</em>
                    </div>
                </v-card-title>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card-text class="py-0">
                This <em>{{taskName}}</em><span v-if="!task.notificationReason"> task</span> was previously assigned to you but the submitter has since decided to withdraw the submission.
            </v-card-text>
            <card-actions>
                <v-btn @click="done"
                       color="primary"
                       text
                       :loading="loading">{{task.okActionName || 'Ok'}}</v-btn>
            </card-actions>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'task-general-notification',
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            loading: false,
        }),
        computed: {
            taskName() {
                const { task } = this;
                return (
                    task.notificationReason ||
                    `${task.stepName} ${
                        task.type === 'Collect Collated Notes' ? ' Notes' : ''
                    }`
                );
            },
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
            }),
        },
        methods: {
            done() {
                this.loading = true;

                if (this.task.type === 'Submission Step') {
                    this.loading = true;
                    this.$store
                        .dispatch('myTasks/processStep', { taskId: this.taskId })
                        .then(() => {
                            this.loading = false;
                        });
                } else
                    this.$store
                        .dispatch('myTasks/dismissTask', {
                            taskId: this.taskId,
                        })
                        .then(() => {
                            this.loading = false;
                        });
            },
        },
    };
</script>