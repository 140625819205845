<template>
    <v-card-text>
        <template v-for="field in availableFields">
            <submission-field-collated-notes v-if="field.type === 'Collated Notes'"
                                             :key="field._id"
                                             :task-id="taskId"
                                             :field="field"
                                             :disabled="disabled">

            </submission-field-collated-notes>
            <submission-field v-else
                              :key="field._id"
                              :task-id="taskId"
                              :field="field"
                              :disabled="disabled"></submission-field>
        </template>
    </v-card-text>
</template>
<script>
    import { mapState } from 'vuex';

    import submissionField from './submission-field';
    import submissionFieldCollatedNotes from './submission-field-collated-notes';

    export default {
        name: 'submission-fields',
        components: {
            submissionField,
            submissionFieldCollatedNotes,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
            disabled: Boolean,
        },
        computed: {
            ...mapState('myTasks', {
                availableFields(state) {
                    const task = state.tasks[this.taskId];
                    return task.fieldsAvailable;
                },
            }),
        },
    };
</script>