export default function (object, fullAccessorName) {
    if (typeof object !== 'object') throw new Error("Can't recursively access something that isn't an object");

    if (fullAccessorName.indexOf('.') === -1) return object[fullAccessorName];

    const allAccessors = fullAccessorName.split('.');

    let current = object || {}, i = 0;
    while (i < allAccessors.length) {
        current = current[allAccessors[i++]];
        if (i === allAccessors.length) return current;
        if (typeof current !== 'object') return undefined;
    }
}