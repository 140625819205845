import Vue from 'vue';
import AppComponent from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import $http from '@/helpers/$http';
import formatters from '@/plugins/formatters';
import handleError from '@/plugins/handleError';
import PortalVue from 'portal-vue';
import axios from 'axios';

// Triger config load
import { getLazy } from './config';

// Setup chart js
import './components/charts/chartJsSetup';

// Add percent calculator
Math.percent = (amount, total) => Math.round(amount / total * 100);

// Formatters
Vue.use({ install: (Vue) => formatters(Vue, router, store) });

// Error Handling
Vue.use({ install: (Vue) => handleError(Vue, router, store) });

Vue.config.productionTip = false

Vue.prototype.$http = $http;
store.$http = $http;

Vue.prototype.$appName = 'Brink Submission Manager';

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
});

// Add Portals
Vue.use(PortalVue);

Promise.all([getLazy(), import('./globalComponents')])
  .then(([config]) => {
    axios.defaults.baseURL = config.api.baseURL;
    $http.defaults.baseURL = `${config.api.baseURL}${$http.defaults.baseURL}`
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(AppComponent)
    }).$mount('#app')
  });