import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        submittableMappings: {},
        fetching: {},
    },
    getters: {
        getIdForSubmittableCategory: state => id => state.submittableMappings[id],
    },
    mutations: {
        addUnknown(state, submittableCategory) {
            // Map to itself
            const { category_id: id, name } = submittableCategory;
            const _id = `unknownCat-${id}`
            Vue.set(state.submittableMappings, id, _id);
            Vue.set(state, _id, { isNew: true, _id, name, colorHex: '#CFD8DC', submittableCategory });
        },
        load(state, { category, oldTemporaryId }) {
            if (oldTemporaryId) {
                const otherOldId = state.submittableMappings[oldTemporaryId];
                if (otherOldId)
                    Vue.delete(state, otherOldId);
                Vue.delete(state.submittableMappings, oldTemporaryId);
            }

            const source = category.source,
                mapped = state.submittableMappings;

            if (source && source.mappedCategoryIds) {
                source.mappedCategoryIds
                    .forEach(id => { Vue.set(mapped, id, category._id) })
            }
            Vue.set(state, category._id, category);
        },
        setFetching(state, { _id, isFetching }) {
            Vue.set(state.fetching, _id, isFetching);
        },
        updateDetails(state, { id, name, colorHex }) {
            const cat = state[id];
            cat.name = typeof name === 'string' ? name : cat.name;
            cat.colorHex = colorHex || cat.colorHex;
        }
    },
    actions: {
        fetch({ commit, state }, { _id }) {
            // Another chip might have already looked this up
            if (state.fetching[_id] || state[_id]) return;
            commit('setFetching', { _id, isFetching: true });

            return this.$http
                .get(`categories/${_id}`)
                .then(({ data: category }) => {
                    commit('setFetching', { _id, isFetching: false });

                    if (category) commit('load', { category });

                    return category;
                });
        }
    }
}