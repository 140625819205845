import _ from 'lodash'
import Vue from 'vue';

import prependUrl from '@/store/organisationsPrependActiveUrlGetter';

export default {
    namespaced: true,
    state: {
        staff: {},
    },
    getters: {
        prependUrl,
        orderedStaff: state => _.values(state.staff).sort((a, b) => {
            const afn = a.firstName.toLowerCase();
            const bfn = b.firstName.toLowerCase();
            return afn < bfn ? -1 : afn > bfn ? 1 : 0
        })
    },
    mutations: {
        staffEmptyAll(state) {
            Vue.set(state, 'staff', {});
        },
        staffLoad(state, { staff }) {
            Vue.set(state.staff, staff._id, staff);
        },
        staffRemove(state, { id }) {
            Vue.delete(state.staff, id);
        },
    },
    actions: {
        staffDelete({ commit, getters }, { id }) {
            return this.$http.delete(getters.prependUrl(`/admin/staff/${id}`))
                .then(resp => {
                    const staff = resp.data;
                    commit('staffRemove', { id });
                    return staff;
                });
        },
        staffLoadAll({ state, commit, getters }, { force }) {
            if (force)
                commit('staffEmptyAll')
            else if (_.keys(state.staff).length)
                return;

            commit('updateStatus', { isLoading: true }, { root: true })

            return this.$http
                .get(getters.prependUrl('/admin/staff'))
                .then(response => {
                    response.data.forEach(staff => commit('staffLoad', { staff }));
                    commit('updateStatus', { isLoading: false }, { root: true })
                })
                .catch(err => this.$handleError(err))
        },
        staffNew({ commit, getters }, details) {
            return this.$http
                .post(getters.prependUrl('/admin/staff'), details)
                .then(resp => {
                    const staff = resp.data;
                    commit('staffLoad', { staff });
                    return staff;
                });
        },
        staffUpdate({ commit, getters }, { changes, id }) {
            return this.$http
                .put(getters.prependUrl(`/admin/staff/${id}`), changes)
                .then(resp => {
                    const staff = resp.data;
                    commit('staffLoad', { staff });
                    return staff;
                });
        }

    }
};