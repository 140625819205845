<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="min-width">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="`${task.notificationReason}: ${task.title}`"
                         class="text-truncate pr-2">
                        <span class="mr-2">{{task.notificationReason}}:</span><em>{{task.title}}</em>
                    </div>
                    <submission-indicators :submission="task"></submission-indicators>
                </v-card-title>
                <v-card-subtitle v-if="task.project"
                                 class="pb-0">{{task.project.name}}</v-card-subtitle>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <flagged-task-alert v-if="task.initialFlaggedWarning"></flagged-task-alert>
            <template v-if="task.files">
                <v-card-subtitle class="pb-0">Files</v-card-subtitle>
                <file-list :files="task.files"
                           @file-selected="downloadFile"></file-list>
            </template>
            <template v-if="task.previousStepDetails">
                <v-card-subtitle class="pb-0">History</v-card-subtitle>
                <previous-step-details :previous-steps="task.previousStepDetails"></previous-step-details>
            </template>
            <card-actions>
                <v-btn @click="done"
                       color="primary"
                       text
                       :loading="loading">{{task.doneActionName || 'Done'}}</v-btn>
            </card-actions>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapState } from 'vuex';

    import flaggedTaskAlert from './flagged-task-alert';
    import fileList from '@/components/file-list';
    import previousStepDetails from '@/components/submission-previous-steps-details';
    import submissionIndicators from '@/components/submission-indicators';

    export default {
        name: 'task-submission-details',
        components: {
            flaggedTaskAlert,
            fileList,
            previousStepDetails,
            submissionIndicators,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            loading: false,
        }),
        computed: {
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
            }),
        },
        methods: {
            done() {
                this.loading = true;
                this.$store
                    .dispatch('myTasks/dismissTask', {
                        taskId: this.taskId,
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
            downloadFile(fileId) {
                this.$store.dispatch('myTasks/downloadSubmissionFile', {
                    taskId: this.taskId,
                    fileId,
                });
            },
        },
    };
</script>