import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

// modules
import categories from './categories';
import files from './files';
import { store as myTasks } from '@/modules/my-tasks';
import organisation from './organisation';


const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    categories,
    files,
    myTasks,
  },
  state: {
    user: {
      firstName: '',
      surname: '',
      username: '',
    },
    error: null,
    errored: false,
    initialised: false,
    loading: true,
    showPageFilters: false,
    snackbarShown: false,
    snackbarText: '',
  },
  getters: {
    flaggedItemMsg: () => 'This submission has been flagged as containing potentially distressing material. If you do not feel comfortable reading it, please let Evan know, and it will be reassigned without question.',
    hasRole: (s, getters) => role => getters['organisation/hasRole'] ? getters['organisation/hasRole'](role) : false,
    hasRoles: (s, getters) => roles => roles.length === 0 ? true : getters['organisation/hasRoles'] ? getters['organisation/hasRoles'](roles) : false,
  },
  mutations: {
    loadUser(state, { user }) {
      const u = state.user;
      u.firstName = user.firstName;
      u.surname = user.surname;
      u.username = user.username;
    },
    initialised(state) {
      state.loading = false;
      state.initialised = true;
    },
    updateStatus(state, { isLoading, isErrored, error }) {
      if (isLoading === !!isLoading) state.loading = isLoading;
      if (isErrored === !!isErrored) state.errored = isErrored;
      if (error) state.error = error;
    },
    setSnackBackDetails(state, { text, isShown }) {
      state.snackbarText = text || state.snackbarText;
      state.snackbarShown = !!isShown;
    },
    togglePageFilters(state, value) {
      state.showPageFilters = value ? !!value.isShown : !state.show;
    },
  },
  actions: {
    initialise({ commit, dispatch }) {
      return this.$http
        .get('/users/current/state/initial')
        .then(({ data }) => {

          commit('loadUser', { user: data.user });

          if (data.categories)
            data.categories.forEach(category => { commit('categories/load', { category }) });

          if (data.user.organisations) {
            store.registerModule('organisation', organisation)

            dispatch('organisation/loadFromInitialState', { organisations: data.user.organisations })
          }

          dispatch('myTasks/loadTasks', { tasks: data.assignedItems || [] });


          commit('initialised');
        })
    },
  },
})

export default store;