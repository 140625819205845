<template>
    <v-list dense
            subheader>
        <file-item v-for="file in files"
                   :key="file._id"
                   :id="file._id"
                   :name="file.name"
                   :disabled="file.isPending"
                   :title="file.isPending ? 'The file is still being processed and so is not available for download yet. We should have it all ready to serve in a few minutes. Please try again later!' : ''"
                   @file-selected="file.isPending || $emit('file-selected', $event)">
        </file-item>
    </v-list>
</template>
<script>
    import fileItem from './file-item';

    export default {
        name: 'file-list',
        components: {
            fileItem,
        },
        props: {
            files: {
                type: Array,
                required: true,
            },
            icon: {
                type: String,
                default: 'fa fa-file-download',
            },
        },
    };
</script>