<template>
    <v-dialog v-model="showBound"
              :persistent="loading"
              width="500">
        <v-card>
            <v-card-title> Add Editor?</v-card-title>
            <v-card-text>You can select a user that you want to add to this round of notes using the below dropdown:</v-card-text>
            <v-card-text>
                <v-select v-model="user"
                          :items="availableAndNotIncludedUsers"
                          :item-text="u => `${u.firstName} ${u.surname}`"
                          item-value="_id"
                          label="Editor"
                          :disabled="loadingUsers || allAdded"
                          :loading="loadingUsers ? 'primary' : undefined"
                          :placeholder="loadingUsers ? 'Loading available editors...' : ''"
                          :messages="messages"></v-select>
            </v-card-text>
            <card-actions>
                <v-btn @click="$emit('add', user)"
                       color="primary"
                       text
                       :disabled="!user"
                       :loading="loading">Add Editor</v-btn>
                <template #secondary>
                    <v-btn text
                           @click="showBound = false"
                           :disabled="loading">
                        Cancel
                    </v-btn>
                </template>
            </card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'field-collated-notes-add',
        props: {
            field: {
                type: Object,
                required: true,
            },
            loading: Boolean,
            loadUsersFunction: {
                type: Function,
                required: true,
            },
            show: {
                type: Boolean,
                required: true,
            },
        },
        data: () => ({
            availableUsers: undefined,
            loadingUsers: false,
            user: undefined,
        }),
        computed: {
            allAdded() {
                return (
                    this.availableAndNotIncludedUsers &&
                    this.availableAndNotIncludedUsers.length === 0
                );
            },
            availableAndNotIncludedUsers() {
                const { existingUserIds } = this;
                return !this.availableUsers
                    ? undefined
                    : this.availableUsers.filter(
                          u => existingUserIds.indexOf(u._id) === -1
                      );
            },
            existingUserIds() {
                return this.field.value.map(v => v.user._id);
            },
            messages() {
                if (this.loadingUsers) return '';
                if (this.allAdded)
                    return 'All available editors have already been added to the notes field';
                return 'The available editors are pre-filtered to those that the notes are collected from';
            },
            showBound: {
                get() {
                    return this.show;
                },
                set(newValue) {
                    this.$emit('update:show', newValue);
                },
            },
        },
        watch: {
            show(newValue, oldValue) {
                if (newValue && !oldValue) this.loadUsers();
            },
        },
        methods: {
            loadUsers() {
                this.loadingUsers = true;
                this.loadUsersFunction().then(({ data: users }) => {
                    this.availableUsers = users;
                    this.loadingUsers = false;
                });
            },
        },
    };
</script>