import store from '@/store'
import { roles } from '@/helpers/security'

const submittableAccess = [roles.submittable]

export default {
    routes: [
        {
            path: 'submissions',
            name: 'Submittable Submissions',
            component: () => import(/* webpackChunkName: "new-submissions" */ '@/modules/submittable/views/submissions-list.vue'),
            meta: {
                requiresSubmittableAccount: true,
                roles: submittableAccess
            },
        },
        {
            path: 'submissions/syncing',
            name: 'Out of Sync Submissions',
            component: () => import(/* webpackChunkName: "submittable-accounts" */ '@/modules/submittable/views/submissions-syncing.vue'),
            meta: {
                roles: submittableAccess
            },
        },
        {
            path: 'accounts',
            name: 'Submittable Accounts',
            component: () => import(/* webpackChunkName: "submittable-accounts" */ '@/modules/submittable/views/submittable-accounts.vue'),
            meta: {
                roles: submittableAccess
            },
        },

    ],
    beforeEach: [
        (to, from, next) => {
            const activeId = store.state.organisation.submittable.activeAccountId;
            const accs = store.getters['organisation/submittable/accounts'];
            // Prevent access to pages when there is no active account
            if (to.matched.some(rec => rec.meta.requiresSubmittableAccount)) {
                let activeAccounts = store.getters['organisation/submittable/activeAccounts'];
                if (!activeAccounts || !activeAccounts.length) {
                    next({
                        path: 'accounts',
                        params: { noAccounts: true }
                    });
                    return;
                }
                else if (!activeId || !accs[activeId] || !accs[activeId].active) {
                    store.dispatch('organisation/submittable/setActiveAccount', { useFirst: true })
                        .then(() => { next(); })
                }
            }
            next();
        }
    ]
}