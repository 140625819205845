<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="min-width">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="`${task.stepName}: ${task.title}`"
                         class="text-truncate pr-2">
                        <span v-if="confirming">Confirm {{selectedOutcomeName || 'Details'}} — </span>
                        <span v-else-if="selectedOutcomeName">{{selectedOutcomeName}} — </span>
                        <span class="mr-2">{{task.stepName}}:</span><em>{{task.title}}</em>
                    </div>
                    <submission-indicators :submission="task"></submission-indicators>
                </v-card-title>
                <v-card-subtitle v-if="task.project"
                                 class="pb-0">{{task.project.name}}</v-card-subtitle>

            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <submitter-details v-if="task.submitter"
                               :submitter="task.submitter"></submitter-details>
            <flagged-task-alert v-if="task.initialFlaggedWarning"></flagged-task-alert>
            <v-card-subtitle class="pb-0">Files</v-card-subtitle>
            <file-list :files="task.files"
                       @file-selected="downloadFile"></file-list>
            <template v-if="previousSteps && previousSteps.length">
                <v-card-subtitle class="pb-0">History</v-card-subtitle>
                <previous-step-details :previous-steps="previousSteps"
                                       :focussed-step="previousStepPosition"></previous-step-details>
            </template>
            <template v-if="!confirming">
                <v-card-subtitle>Fields</v-card-subtitle>
                <submission-fields :task-id="task._id"
                                   :disabled="disableFields || confirming"></submission-fields>
            </template>
            <outcome-actions :task-id="task._id"
                             @loading="disableFields = $event"></outcome-actions>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapState } from 'vuex';

    import flaggedTaskAlert from './flagged-task-alert';
    import fileList from '@/components/file-list';
    import outcomeActions from '@/modules/my-tasks/components/outcome-actions';
    import previousStepDetails from '@/components/submission-previous-steps-details';
    import submissionFields from '@/modules/my-tasks/components/submission-fields';
    import submissionIndicators from '@/components/submission-indicators';
    import submitterDetails from '@/components/submitter-details';

    export default {
        name: 'task-submission-step',
        components: {
            flaggedTaskAlert,
            fileList,
            outcomeActions,
            previousStepDetails,
            submissionFields,
            submissionIndicators,
            submitterDetails,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                disableFields: false,
                previousStepPosition: undefined,
            };
        },
        computed: {
            confirming() {
                return this.task.confirmTask;
            },
            previousSteps() {
                const task = this.task;
                return !this.confirming
                    ? task.previousStepDetails
                    : [
                          ...(task.previousStepDetails || []),
                          {
                              _id: task.stepId,
                              name: task.stepName,
                              state: 'Active',
                              fields: task.fieldsAvailable,
                              completedOn: Date.now(),
                              outcome: task.selectedOutcome,
                              assignee: this.user,
                          },
                      ];
            },
            selectedOutcomeName() {
                return this.task.selectedOutcome
                    ? this.task.selectedOutcome.name
                    : undefined;
            },
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
            }),
            ...mapState(['user']),
        },
        watch: {
            confirming(newValue, oldValue) {
                if (newValue && !oldValue)
                    this.previousStepPosition = this.previousSteps.length - 1;
                else if (!newValue && oldValue)
                    this.previousStepPosition = this.previousSteps.length - 2;
            },
        },
        methods: {
            downloadFile(fileId) {
                this.$store.dispatch('myTasks/downloadSubmissionFile', {
                    taskId: this.taskId,
                    fileId,
                });
            },
        },
    };
</script>