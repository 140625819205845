import { sortFieldsByOrder, sortFieldsAllCollatedNotes } from '@/helpers/sorts';

export default {
    namespaced: true,
    state: {
        items: {},
        order: [],
        loadedAll: false,
        loading: false,
    },
    getters: {
        pageCurrentPosition: state => state.order.length / 10,
        pageNext: (state, getters) => state.loadedAll ? -1 : Math.floor(getters.pageCurrentPosition) + 1,
        urlBase: (s, g, rs, rootGetters) => `${rootGetters['myTasks/urlBase']}/history`,
    },
    mutations: {
        loadedAll(state) {
            state.loadedAll = true;
        },
        loading(state, { isLoading }) {
            state.loading = !!isLoading;
        },
        setPage(state, pageNumber) {
            state.currentPage = pageNumber;
        },
        /** Load an existing submissions */
        load(state, { item, topOfQueue }) {
            const id = item.action === 'Step Completed'
                ? item.step._id
                : item.action === 'Collated Notes Completed'
                    ? item.valueId
                    : undefined

            if (item.step && Array.isArray(item.step.fields))
                item.step.fields = sortFieldsAllCollatedNotes(item.step.fields).sort(sortFieldsByOrder(item.step.fields));

            if (id && !state.items[id]) {
                item.uniqueId = id;
                state.items[id] = item;

                const queueItem = { id, action: item.action, };

                if (topOfQueue)
                    state.order.unshift(queueItem);
                else
                    state.order.push(queueItem);
            }
        },
    },
    actions: {
        createFromCompletedTask({ dispatch, getters, rootState, state }, { taskId }) {
            // Only push if we have already loaded previous tasks.
            // If so, we will be ahead of where this task fits in
            // if not, it will get loaded on first load and will be more accurate
            if (getters.pageCurrentPosition > 0 || state.loadedAll) {
                const task = rootState.myTasks.tasks[taskId];
                const me = rootState.user; // TODO: should the user have an id

                if (task.type === 'Collect Collated Notes')
                    // This should match what we get out of the database for saved tasks
                    dispatch('newCollatedNotes', { task, user: me });
                else if (task.type === 'Submission Step')
                    dispatch('newSubmissionStepFromTask', { task, user: me });
            }
        },
        downloadSubmissionFile({ commit, getters, state }, { historyRecordId, itemId, fileId }) {
            const sub = state.items[itemId];
            const file = sub.files.find(f => f._id === fileId);
            commit('files/setDownloading', { fileId, isDownloading: true }, { root: true, });
            return this.$http
                .get(`${getters.urlBase}/${historyRecordId}/files/${fileId}`, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: response.headers['content-type']
                    })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = file.name;
                    link.click()

                    commit('files/setDownloading', { fileId, isDownloading: false }, { root: true, });
                });
        },
        loadNextPage({ getters, commit }) {
            commit('loading', { isLoading: true });
            return this.$http
                .get(`${getters.urlBase}?page=${getters.pageNext}`)
                .then(({ data }) => {
                    commit('loading', { isLoading: false });
                    data.forEach(item => { commit('load', { item }) });

                    // We add newly completed tasks in, so once we get to the end, there can't be more
                    if (data.length < 10) commit('loadedAll');
                });
        },
        /** Add a recently compeleted submissions */
        newSubmissionStepFromTask({ state, commit }, { task, user }) {
            const id = task.stepId;
            if (!state.items[id]) {
                const submissionStep = {
                    _id: task.submissionId,
                    action: 'Step Completed',
                    category: task.category,
                    files: task.files,
                    project: task.project,
                    submissionCreatedOn: task.createdDateSubmission,
                    title: task.title,
                    flaggedWarning: task.flaggedWarning,
                    step: {
                        _id: task.stepId,
                        projectStepId: task.projectStepId,
                        assignee: user,
                        completedOn: Date.now(),
                        createdDate: task.createdDateTask,
                        createdFrom: task.createdFrom,
                        fields: task.fieldsAvailable,
                        name: task.stepName,
                        // TODO: Warning - this is not always set, only if an outcome is selected
                        // and not if, for example the task is graded.
                        // Perhaps the answer is to select an outcome based on the graded threshold in app?
                        outcome: task.selectedOutcome,
                        state: 'Completed',
                        previousStepDetails: task.previousStepDetails,
                    },
                    submitter: task.submitter,
                }

                commit('load', { item: submissionStep, topOfQueue: true });
            }
        },
        newCollatedNotes({ commit, state }, { task }) {
            const id = task.valueId;
            if (!state.items[id]) {
                const collatedNotes = {
                    action: 'Collated Notes Completed',
                    actionedDate: Date.now(),
                    submissionCreatedOn: task.createdDateSubmission,
                    ...task,
                }

                commit('load', { item: collatedNotes, topOfQueue: true });
            }
        }
    }
}