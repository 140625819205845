import Vue from 'vue';
import sortSubmissionFields from './sortSubmissionFields';

export default {
    namespaced: true,
    state: {
        activeWorktray: null,
        activeWorktrayItemsToLoad: 10,
        submissionsLoaded: {},
        worktrays: null,
    },
    getters: {
        activeWorktrayPage: state => state.activeWorktray.items.slice(0, state.activeWorktrayItemsToLoad),
        activeWorktrayTotalItems: state => state.activeWorktray.items.length,
        reasons: state => {
            return !state.activeWorktray || !state.activeWorktray.reasons
                ? undefined
                : state.activeWorktray.reasons.reduce(
                    (reasons, reason) => { reasons[reason._id] = reason; return reasons; },
                    {}
                );
        },
        submissionById: state => id => state.submissionsLoaded[id],
        submissionIsLoaded: state => id => !!state.submissionsLoaded[id],
        urlBase: (state, getters) => `${getters.urlProjectActive}/worktrays/${state.activeWorktray._id}`,
        urlFile: (s, getters) => (submissionId, fileId) => `${getters.urlProjectActive}/submissions/${submissionId}/files/${fileId}`,
        urlProjectActive: (s, g, rs, rootGetters) => rootGetters['organisation/projects/activeProjectUrl'],
        urlSubmissionById: (s, g, rs, rootGetters) => id => rootGetters['organisation/projects/urlSubmissionById'](id),
        worktrayById: state => id => state.worktrays[id],
    },
    mutations: {
        activeWorktrayItemAdd(state, { submissionId, reasonId }) {
            if (state.activeWorktray)
                state.activeWorktray.items.push({
                    _id: submissionId,
                    reasonId
                });
        },
        activeWorktrayItemRemove(state, { submissionId, reasonId }) {
            if (state.activeWorktray) {
                const { items } = state.activeWorktray;
                const item = items.find(i => i._id === submissionId && i.reasonId === reasonId);
                if (item)
                    Vue.delete(items, items.indexOf(item));
            }
        },
        activeWorktrayChangePageSize(state, { loadMore = false, itemProcessed = false }) {
            if (itemProcessed)
                state.activeWorktrayItemsToLoad = Math.max(state.activeWorktrayItemsToLoad - 1, 10);

            if (loadMore)
                state.activeWorktrayItemsToLoad += 10;
        },
        loadActiveWorktray(state, { worktray, clearLoadedSubmissions }) {
            // Clear loaded submissions when we change worktray
            if (!state.activeWorktray || state.activeWorktray._id !== worktray._id)
                state.submissionsLoaded = {};

            const { items } = worktray;
            // If the worktray has submissions as objects, transform it and flatten the structure
            // TODO: Loading Worktray Items
            if (Array.isArray(items)) {
                worktray.items = items.map(i => { return { _id: i._id, reasonId: i.reasonId } });
            }

            state.activeWorktray = worktray;

            if (clearLoadedSubmissions)
                state.submissionsLoaded = {};
        },
        projectHasChanged(state) {
            // reset the values if the project changes
            state.submissionsLoaded = {};
            state.worktrays = {};
        },
        setReasonColor(state, { reasonId, colorHex }) {
            const reason = state.activeWorktray.reasons.find(r => r._id === reasonId);
            if (reason) Vue.set(reason, 'colorHex', colorHex);
        },
        submissionHasBeenDeleted(state, { submissionId }) {
            Vue.delete(state.submissionsLoaded, submissionId);

            const { activeWorktray } = state;
            if (activeWorktray) {
                const { items } = activeWorktray;
                const sub = items.find(s => s._id === submissionId);
                if (sub) {
                    Vue.delete(items, items.indexOf(sub));
                }
            }

        },
        submissionFileDetailsUpdate(state, { submissionId, fileId, fileDetails }) {
            const sub = state.submissionsLoaded[submissionId];
            const file = sub.files.find(f => f._id === fileId);
            Vue.set(sub.files, sub.files.indexOf(file), fileDetails);
        },
        submissionLoad(state, submission) {
            // TODO: Can we minimise the amount of loading & sharing going on
            sortSubmissionFields(submission);

            /** Track the existing step assignments in a friendly way - saves lots of lookups during assignments */
            submission._existingAssigns =
                submission.steps.reduce((all, step) => {
                    const cf = step.createdFrom;
                    if (cf.type === 'Worktray') {
                        const assignees = all[cf.worktrayId] = all[cf.worktrayId] || {};
                        assignees[step.assignee._id] = cf.reasonId;
                    }
                    return all;
                }, {});

            Vue.set(state.submissionsLoaded, submission._id, submission);
        },
        submissionRemove(state, { submissionId }) {
            // Clear up as we go along, it will reload if we accidently delete it
            Vue.delete(state.submissionsLoaded, submissionId);
        },
        worktrayLoad(state, worktray) {
            Vue.set(state.worktrays, worktray._id, worktray);
        },
        worktrayCountUpdate(state, { worktrayId, addToCount }) {
            const worktray = state.worktrays[worktrayId];
            if (worktray)
                worktray.itemCount += addToCount

            if (worktray.itemCount < 0)
                worktray.itemCount = 0;
        }
    },
    actions: {
        activeWorktrayLoad({ commit }, { worktray, clearLoadedSubmissions }) {
            const { firstPage } = worktray;
            delete worktray.firstPage;

            commit('loadActiveWorktray', { worktray, clearLoadedSubmissions });

            if (Array.isArray(firstPage)) { firstPage.forEach(sub => commit('submissionLoad', sub)); }
        },
        assignSubmission({ commit, dispatch, getters }, { assigneeId, submissionId }) {
            return this.$http.post(`${getters.urlBase}/${submissionId}`,
                { assigneeId: assigneeId }
            )
                .then(({ data }) => {
                    return dispatch('organisation/projects/activeProjectLoad', data.project, { root: true })
                        .then(() => dispatch('activeWorktrayLoad', { worktray: data.worktray }))
                        .then(() => commit('submissionRemove', { submissionId }))
                });
        },
        processSubmission({ commit, dispatch, getters }, { submissionId, worktrayData }) {
            return this.$http.post(`${getters.urlBase}/${submissionId}`, worktrayData)
                .then(({ data }) => {
                    commit('activeWorktrayChangePageSize', { itemProcessed: true });
                    return dispatch('organisation/projects/activeProjectLoad', data.project, { root: true })
                        .then(() => dispatch('activeWorktrayLoad', { worktray: data.worktray }))
                        .then(() => commit('submissionRemove', { submissionId }))
                });
        },
        setReasonColor({ commit, getters }, { reasonId, colorHex }) {
            commit('setReasonColor', { reasonId, colorHex });

            this.$http.put(`${getters.urlBase}/reasons/${reasonId}/color`, { colorHex });
        },
        submissionFetch({ commit, getters }, { submissionId }) {
            return this.$http.get(getters.urlSubmissionById(submissionId))
                .then(({ data }) => {
                    commit('submissionLoad', data)
                    return data;
                });
        },
        submissionFileUploadReplacement({ commit, getters }, { submissionId, fileId, file }) {
            commit('files/setUploading', { fileId, isUploading: true }, { root: true, });
            this.$http
                .put(getters.urlFile(submissionId, fileId), file, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-File-Content-Type': file.type,
                        'X-File-Extension': (file.name.match(/.+\.(\w+)/) || [])[1],
                    },
                })
                .then(({ data: fileDetails }) => {
                    commit('submissionFileDetailsUpdate', { submissionId, fileId, fileDetails });
                    commit('files/setUploading', { fileId, isUploading: false }, { root: true, });
                });
        },
        switch({ getters, dispatch }, { worktrayId }) {
            return this.$http.get(`${getters.urlProjectActive}/worktrays/${worktrayId}`)
                .then(({ data }) => dispatch('activeWorktrayLoad', { worktray: data }));
        },
        worktraysUpdateCounts({ commit }, worktraysToUpdate) {
            if (Array.isArray(worktraysToUpdate))
                worktraysToUpdate.forEach(({ worktrayId, addToCount }) => {
                    commit('worktrayCountUpdate', { worktrayId, addToCount });
                });
            else {
                const { worktrayId, addToCount } = worktraysToUpdate;
                commit('worktrayCountUpdate', { worktrayId, addToCount });
            }

            commit('organisation/projects/activeProjectUpdateItemCountFromWorktrays', undefined, { root: true });
        },
    }
}