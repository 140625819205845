<template>
    <v-chip v-on="$listeners"
            :value="value"
            :color="color"
            :text-color="textColor"
            :close="close"
            :close-icon="closeIcon"
            :disabled="disabled"
            :small="small"
            class="category-chip overflow-visible font-weight-regular">
        <v-avatar v-if="count"
                  left
                  :class="`${breakPointLargerClass}-flex grey darken-2 ${blendMode}`">
            {{count}}
        </v-avatar>
        <span :class="breakPointSmallerClass"
              :title="name">{{count}}</span>
        <span :class="`${breakPointLargerClass} text-truncate`"
              :title="name">{{name}}</span>
        <slot name="right"></slot>
    </v-chip>
</template>
<script>
    import { mapState } from 'vuex';
    import { textColorForBg } from '@/helpers/colors';

    export default {
        name: 'category-chip',
        props: {
            breakpoint: String,
            categoryId: {
                type: String,
                required: true,
            },
            close: Boolean,
            closeIcon: String,
            count: Number,
            disabled: Boolean,
            noColor: Boolean,
            small: Boolean,
            submittableCategoryId: Number,
            submittableIdAsValue: Boolean,
        },
        computed: {
            blendMode() {
                return this.textColor === 'white'
                    ? 'blend__screen'
                    : 'blend__hard-light';
            },
            breakPointSmallerClass() {
                return this.breakpoint ? 'd-inline d-lg-none' : 'd-none ';
            },
            breakPointLargerClass() {
                return this.breakpoint && this.count !== undefined
                    ? 'd-none d-lg-inline'
                    : 'd-inline';
            },
            color() {
                return this.noColor
                    ? 'grey lighten-3'
                    : this.category.colorHex || '#CFD8DC';
            },
            name() {
                return this.category.name;
            },
            textColor() {
                return this.noColor ? 'grey darken-2' : textColorForBg(this.color);
            },
            value() {
                return this.submittableIdAsValue && this.submittableCategoryId
                    ? this.submittableCategoryId
                    : this.categoryId;
            },
            ...mapState('categories', {
                category(state) {
                    return state[this.categoryId] || {};
                },
            }),
        },
        created() {
            if (!Object.keys(this.category).length)
                this.$store.dispatch('categories/fetch', { _id: this.categoryId });
        },
    };
</script>
<style scoped>
    .blend__screen,
    .blend__hard-light {
        font-size: 1.2em;
    }
    .blend__screen {
        mix-blend-mode: screen;
    }
    .blend__hard-light {
        mix-blend-mode: difference;
        font-weight: 700;
    }
</style>
<style>
    .category-chip.v-size--small .v-avatar {
        height: 22px !important;
        min-width: 22px !important;
        width: 22px !important;
    }
</style>