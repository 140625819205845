<template>
    <v-alert icon="flag"
             color="red accent-4"
             text
             dense
             class="flagged-alert">{{flaggedItemMsg}}</v-alert>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'flagged-task-alert',
        computed: {
            ...mapGetters(['flaggedItemMsg']),
        },
    };
</script>
<style>
    .flagged-alert {
        width: 97%;
        margin: auto;
    }
    .flagged-alert .v-alert__icon {
        display: contents;
    }
    .flagged-alert .v-alert__content {
        margin-left: 1em;
    }
</style>