<template>
    <v-dialog v-model="showBound"
              :persistent="loading"
              width="500">
        <v-card>
            <v-card-title>Remove Editor?</v-card-title>
            <template v-if="field.value.length">
                <v-card-text>You can select a user that you need to remove from this round of notes using the below dropdown:</v-card-text>
                <v-card-text>
                    <v-select v-model="user"
                              :items="field.value"
                              item-text="displayName"
                              item-value="_id"
                              label="Editor"
                              hide-details></v-select>
                </v-card-text>
            </template>
            <v-card-text v-else>There are no editors to remove...</v-card-text>
            <card-actions>
                <v-btn @click="$emit('remove', user)"
                       color="primary"
                       text
                       :disabled="!user"
                       :loading="loading">Remove Editor</v-btn>
                <template #secondary>
                    <v-btn text
                           @click="showBound = false"
                           :disabled="loading">
                        Cancel
                    </v-btn>
                </template>
            </card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'field-collated-notes-remove',
        props: {
            field: {
                type: Object,
                required: true,
            },
            loading: Boolean,
            show: {
                type: Boolean,
                required: true,
            },
        },
        data: () => ({
            user: undefined,
        }),
        computed: {
            showBound: {
                get() {
                    return this.show;
                },
                set(newValue) {
                    this.$emit('update:show', newValue);
                },
            },
        },
    };
</script>