<template>
    <app-navigation>
        <div class="d-flex align-center"
             style="height: 100%;">
            <div class="mx-auto text-center"
                 style="position: relative; top: -12vh">
                <h2 class="mb-2">403 - Unauthorized</h2>
                <p><em>Woah there, what are you up to?</em></p>
                <p><em>You've tried to access something that has not been shared with you. If you think this is incorrect, please get in touch</em></p>
                <v-btn to="/"
                       class="mx-auto">Home</v-btn>
            </div>
        </div>
    </app-navigation>
</template>

<script>
    import appNavigation from '@/components/app-navigation';

    export default {
        name: 'unauthorized-403',
        components: {
            appNavigation,
        },
    };
</script>