<template>
    <app-navigation no-menu>
        <div class="d-flex align-center"
             style="height: 100%;">
            <div class="mx-auto text-center"
                 style="position: relative; top: -12vh">
                <h2 class="mb-2">401 - Unauthenticated</h2>
                <p><em>This shouldn't have been possible! I'm blaming Microsoft, but sorry you ended up here.</em></p>
                <p><em>Please could you let us know that you did and what you were doing!</em></p>
            </div>
        </div>
    </app-navigation>
</template>

<script>
    import appNavigation from '@/components/app-navigation';

    export default {
        name: 'unauthenticated-401',
        components: {
            appNavigation,
        },
    };
</script>