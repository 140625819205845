import { sortFieldsAllCollatedNotes } from '@/helpers/sorts';

export default function (submission) {
    if (submission.steps)
        submission.steps.forEach(step => {
            if (Array.isArray(step.fields))
                step.fields = sortFieldsAllCollatedNotes(step.fields);

            if (Array.isArray(step.previousStepDetails)) {
                step.previousStepDetails.forEach(psd => {
                    if (Array.isArray(psd.fields))
                        psd.field = sortFieldsAllCollatedNotes(psd.fields);
                })
            }
        })
}