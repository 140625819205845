<template>
    <v-card-subtitle class="py-0 csr-pointer"
                     :title="`Click to copy ${this.namePossessive} email address.`"
                     @click="copyEmail">{{prefix}} <em>{{submitter.firstName}} {{submitter.surname}} - {{submitter.email}}</em></v-card-subtitle>
</template>

<script>
    import { mapMutations } from 'vuex';
    import changeNounToPossessive from '@/helpers/changeNounToPossessive';
    import copyToClipboard from '@/helpers/copyToClipboard';

    export default {
        name: 'submitter-details',
        props: {
            prefix: {
                type: String,
                default: 'Submitter:',
            },
            submitter: {
                type: Object,
                required: true,
            },
        },
        computed: {
            namePossessive() {
                return changeNounToPossessive(this.submitter.firstName);
            },
        },
        methods: {
            copyEmail() {
                copyToClipboard(this.submitter.email, this.$el);

                this.setSnackBackDetails({
                    isShown: true,
                    text: `Copied <em>${this.namePossessive}</em> email address to your clipboard`,
                });
            },
            ...mapMutations(['setSnackBackDetails']),
        },
    };
</script>