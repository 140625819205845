import Vue from 'vue';
import fileNameInvalidRegex from '@/helpers/fileNameInvalidRegex';

export default {
    namespaced: true,
    state: {},
    mutations: {
        ensureSubmission(state, { submission }) {
            const submissionId = submission._id;
            // TODO: This is currently inited by the Vue component
            // I would prefer it to come from the root store ./index
            if (!state[submissionId]) {
                const submissionCleaning = {
                    _id: submissionId,
                    files: submission.files.reduce((files, file) => {
                        files[file._id] = {
                            _id: file._id,
                            invalidChars: findInvalidChars(file.name),
                            name: `${file.name.replace(new RegExp(`\\.${file.extension}$`), '')}_[${submissionId}].${file.extension}`,
                            nameWorking: file.name.replace(`.${file.extension}`, ''),
                        }
                        return files;
                    }, {}),
                    title: submission.title,
                };

                submissionCleaning.isInvalid = modificationsAreInvalid(submissionCleaning);

                Vue.set(state, submission._id, submissionCleaning)
            }

        },
        setTitle(state, { submissionId, title }) {
            const modifications = state[submissionId];
            modifications.title = title;

            modifications.isInvalid = modificationsAreInvalid(modifications);
        },
        setFileName(state, { submissionId, fileId, name, extension }) {
            const modifications = state[submissionId];

            let file = modifications.files[fileId]
            file.nameWorking = name;
            file.name = `${name}_[${submissionId}].${extension}`;
            Vue.set(file, 'invalidChars', findInvalidChars(name));

            modifications.isInvalid = modificationsAreInvalid(modifications);
        },
    },
    actions: {
        processSubmission({ dispatch, rootGetters, state }, { submissionId }) {
            const mods = state[submissionId];
            if (modificationsAreInvalid(mods)) return;

            const sub = rootGetters['organisation/projects/activeProjectWorktrays/submissionById'](submissionId);

            const cleanModifications = {};

            if (sub.title !== mods.title)
                cleanModifications.title = mods.title;

            // Find changed files
            const files = Object.values(mods.files)
                .map(f => ({
                    _id: f._id,
                    name: f.name,
                }))
                .filter(f => {
                    const orig = sub.files.find(sf => sf._id = f._id);
                    return orig.name !== f.name;
                });

            if (files.length);

            cleanModifications.files = files;

            return dispatch(
                'organisation/projects/activeProjectWorktrays/processSubmission',
                {
                    submissionId: sub._id,
                    worktrayData: { modifications: cleanModifications },
                },
                { root: true }
            )
        }
    }
}

function findInvalidChars(name) {
    return (name.match(fileNameInvalidRegex) || []).filter(
        (char, index, invalidChars) => invalidChars.indexOf(char) === index
    );
}

function modificationsAreInvalid(modifications) {
    return modifications.title === '' ||
        !!Object.values(modifications.files)
            .find(f => f.workingName === '' || f.invalidChars.length);
}
