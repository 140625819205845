<template>
    <v-btn text
           @click="saveTask"
           title="Save changes (to complete and submit later)"
           :loading="loading">
        <v-icon>far fa-save</v-icon>
    </v-btn>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'save-task-btn',
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapState('myTasks', {
                loading: function(state) {
                    return !!state.savingTasks[this.taskId];
                },
            }),
        },
        methods: {
            saveTask() {
                this.$store.dispatch('myTasks/save', { taskId: this.taskId });
            },
        },
    };
</script>