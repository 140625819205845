<template>
    <div class="field-wrapper">
        <field-checkbox v-if="field.type === 'Checkbox'"
                        v-model="valueBound"
                        :field="field"
                        :readonly="readonly && field.type === 'Checkbox'"
                        :disabled="disabled"
                        :hide-details="hideDetails"
                        @blur="inputBlur"
                        @focus="inputFocus"></field-checkbox>
        <field-checkbox v-else-if="field.type === 'Submission Warning Flag'"
                        v-model="valueBound"
                        :field="field"
                        :readonly="readonly && field.type === 'Checkbox'"
                        :disabled="disabled || readonly"
                        :hide-details="hideDetails"
                        @submission-flagged-warning="$emit('submission-flagged-warning', $event)"
                        @blur="inputBlur"
                        @focus="inputFocus"></field-checkbox>
        <field-collated-notes v-else-if="field.type === 'Collated Notes'"
                              :field="field"
                              :readonly="readonly"
                              :disabled="disabled"
                              :hide-details="hideDetails"
                              @blur="inputBlur"
                              @focus="inputFocus"
                              @collated-notes-modify-editors="$emit('collated-notes-modify-editors', $event)"></field-collated-notes>
        <field-rich-text v-else-if="field.type === 'Rich Text'"
                         v-model="valueBound"
                         :field="field"
                         :readonly="readonly"
                         :disabled="disabled"
                         :hide-details="hideDetails"></field-rich-text>
        <v-textarea v-else-if="field.type === 'Multiline Text'"
                    v-model="valueBound"
                    :label="field.label"
                    :class="{'resize--both': !disableExpandX}"
                    :readonly="readonly"
                    :disabled="disabled"
                    :error-messages="field.validationMessage"
                    :hide-details="hideDetails"
                    @blur="inputBlur"
                    @focus="inputFocus"
                    outlined
                    rows="5"></v-textarea>
        <field-select v-else-if="field.type === 'Grade'"
                      v-model="valueBound"
                      :field="field"
                      :readonly="readonly"
                      :disabled="disabled"
                      :hide-details="hideDetails"
                      @blur="inputBlur"
                      @focus="inputFocus"></field-select>
        <field-user-select v-else-if="field.type === 'User Select'"
                           v-model="valueBound"
                           :field="field"
                           :readonly="readonly"
                           :disabled="disabled"
                           :hide-details="hideDetails"
                           @blur="inputBlur"
                           @focus="inputFocus"></field-user-select>
        <p v-else>{{field.label}} (no style)</p>
        <v-btn v-if="readonly && field.type === 'Multiline Text'"
               @click="copyValue"
               :title="`Copy the '${field.label}' to your clipboard`"
               text
               class="copy-field-btn"
               color="grey darken-1"
               fab
               small>
            <v-icon>fa-copy</v-icon>
        </v-btn>
        <slot name="append"></slot>
    </div>
</template>
<script>
    import { mapMutations } from 'vuex';
    import copyToClipboard from '@/helpers/copyToClipboard';

    import fieldCheckbox from './field-checkbox';
    import fieldCollatedNotes from './field-collated-notes';
    import fieldRichText from './field-rich-text';
    import fieldSelect from './field-select';
    import fieldUserSelect from './field-user-select';

    export default {
        name: 'field-base',
        components: {
            fieldCheckbox,
            fieldCollatedNotes,
            fieldRichText,
            fieldSelect,
            fieldUserSelect,
        },
        props: {
            field: {
                type: Object,
                required: true,
            },
            disabled: Boolean,
            hideDetails: Boolean,
            readonly: Boolean,
            value: [String, Number, Boolean, Object, Array],
            /** Disabled the ability to expand text boxes in the x direction */
            disableExpandX: Boolean,
            /** If available, is used for getting the users firstname when copying from fields */
            submitter: Object,
        },
        computed: {
            valueBound: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                },
            },
        },
        methods: {
            copyValue() {
                let value;
                let existingElement;

                if (
                    this.field.replaceLetterAuthorNamePlaceholder &&
                    this.submitter &&
                    this.valueBound.startsWith &&
                    this.valueBound.startsWith('Dear [')
                )
                    value = this.valueBound.replace(
                        /^Dear \[[\w\s]+\],/i,
                        `Dear ${this.submitter.firstName},`
                    );
                else existingElement = this.$el.querySelector('textarea');

                copyToClipboard(value, this.$el, existingElement);

                this.setSnackBackDetails({
                    isShown: true,
                    text: `Copied <em>${this.field.label}</em> to your clipboard`,
                });
            },
            inputBlur(event) {
                this.$emit('blur', event);
            },
            inputFocus(event) {
                this.$emit('focus', event);
            },
            ...mapMutations(['setSnackBackDetails']),
        },
    };
</script>
<style scoped>
    .field-wrapper {
        position: relative;
    }

    .copy-field-btn {
        position: absolute;
        top: -0.75em;
        right: -0.9em;
        transition: all 0.3s ease;
        opacity: 0;
        transform: scale(0.3);
    }

    .field-wrapper:hover .copy-field-btn {
        opacity: 1;
        top: 0.75em;
        right: 0.75em;
        transition: all 0.2s ease;
        transform: scale(1);
    }
</style>