<template>
    <v-slide-group :value="position"
                   show-arrows
                   class="previous-steps">
        <v-slide-item v-for="step in previousSteps"
                      :key="step._id">
            <v-card class="my-2 mx-2 previous-step"
                    shaped>
                <v-card-title v-if="step.state === 'Active'"
                              class="justify-space-between flex-nowrap">
                    <span>{{step.name}}</span>
                    <v-chip label
                            color="green darken-2"
                            text-color="white"
                            class="ml-2">Active Step</v-chip>
                </v-card-title>
                <v-card-title v-else>{{step.name}}</v-card-title>

                <v-card-subtitle v-if="step.outcome || step.assignee">
                    <div v-if="step.outcome"
                         class="d-flex justify-space-between flex-nowrap pt-1">
                        <em>{{step.outcome.name}}</em>
                        <span class="d-none d-md-inline mx-1">on <em>{{$displayDate(step.completedOn)}}</em></span>
                    </div>
                    <span v-if="step.assignee">Assignee: </span><em>{{step.assignee.firstName}} {{step.assignee.surname}}</em>
                </v-card-subtitle>
                <v-card-text v-if="step.fields"
                             class="previous-step__details">
                    <field-base v-for="field in sortFields(step)"
                                :key="field._id"
                                :value="field.value"
                                :field="field"
                                :submitter="submitter"
                                readonly
                                hide-details
                                disable-expand-x
                                class="mb-3"></field-base>
                </v-card-text>
            </v-card>
        </v-slide-item>
    </v-slide-group>
</template>
<script>
    import { sortFieldsByOrder, sortFieldsAllCollatedNotes } from '@/helpers/sorts';

    import fieldBase from '@/components/field-base';

    export default {
        name: 'previous-step-details',
        components: {
            fieldBase,
        },
        props: {
            previousSteps: {
                type: Array,
                required: true,
            },
            /** If available, is used for getting the users firstname when copying from fields */
            submitter: Object,
            focussedStep: Number,
        },
        data() {
            const stepCount = this.previousSteps.length;

            // Start with the most revent step
            return {
                position:
                    this.focussedStep >= 0 && this.focussedStep < stepCount
                        ? this.focussedStep
                        : this.previousSteps.length - 1,
            };
        },
        watch: {
            focussedStep(newValue) {
                this.position = newValue;
            },
        },
        methods: {
            sortFields(step) {
                const fields = sortFieldsAllCollatedNotes(step.fields.slice());
                return fields.sort(sortFieldsByOrder(fields));
            },
        },
    };
</script>
<style scoped>
    .previous-step {
        min-width: 25vw;
        max-width: 60vw;
    }

    .previous-step:only-child {
        max-width: 80vw;
    }

    @media (min-width: 960px) {
        .previous-step {
            max-width: 40vw;
        }

        .previous-step:only-child {
            width: 65vw;
        }
    }

    @media (min-width: 1175px) {
        .previous-step:only-child {
            width: 55vw;
        }
    }

    @media (min-width: 1500px) {
        .previous-step:only-child {
            width: 45vw;
        }
    }

    @media (min-width: 1500px) {
        .previous-step:only-child {
            width: 40vw;
        }
    }

    @media (min-width: 1904px) {
        .previous-step,
        .previous-step:only-child {
            max-width: 800px;
        }
    }

    /*.previous-steps {
        max-height: 45vh;
    }

    .previous-step {
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        padding-bottom: 1em;
    }

    .previous-step__details {
        overflow-y: auto;
        height: 100%;
        padding-top: 0.33em;
    }*/
</style>