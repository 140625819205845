import _ from 'lodash'

export default function (Vue, router, store) {
    Vue.prototype.$handleError = handleErrorFromComponent;

    store.$handleError = (reason, shouldThrowError = false) => handleErrorFromStore(reason, router, shouldThrowError);
    store.$handleError.rejectWith = rejectionActions;

    router.onError((err) => handleErrorFromRouter.call(router, err));
    router.$handleError = handleErrorFromRouter;
    router.$handleError.rejectWith = rejectionActions;

    // Try and catch unhandled errors
    window.onunhandledrejection = function (err) {
        handleErrorFromRouter.call(router, err)
    }
}

/**
 * Shared error handling from inside Vue Components
 * @param {Error} reason - The error or issue 
 * @param {Boolean} shouldThrowError - If there is more error handling implemented, the error can be thrown back out. Otherwise the app will fall to an errored state
 */
export function handleErrorFromComponent(reason, shouldThrowError = false) {
    return handleErrorFromRouter.call(this.$router, reason, shouldThrowError)
}

/**
 * Shared error handling from inside the router
 * @param {Error} reason - The error or issue
 * @param {Boolean} shouldThrowError - If there is more error handling implemented, the error can be thrown back out. Otherwise the app will fall to an errored state
 */
export function handleErrorFromRouter(reason, shouldThrowError = false) {
    if (reason.canHandleRejection) {
        if (reason.routeTo)
            return this.push(reason.routeTo, reason.onComplete, reason.onAbort);
    }

    // Check for 
    const resp = reason.response;
    if (resp) {
        if (resp.status === 401 && resp.data.newUser && resp.data.username)
            return this.replace({
                name: 'Unknown User',
                params: { username: resp.data.username }
            })
        else if (resp.status === 401)
            return this.replace('/401');
        else if (resp.status === 403)
            return this.replace('/403');
    }

    if (!shouldThrowError)
        this.app.$store.commit('updateStatus', { isErrored: true, isLoading: false, error: reason });
    else
        throw reason;
}

/**
 * Shared error handling from inside Vue Components
 * @param {Error} reason - The error or issue 
 * @param {VueRouter} router - The Vue router
 * @param {Boolean} shouldThrowError - If there is more error handling implemented, the error can be thrown back out. Otherwise the app will fall to an errored state
 */
export function handleErrorFromStore(reason, router, shouldThrowError = false) {
    return handleErrorFromRouter.call(router, reason, shouldThrowError)
}

export const rejectionActions = {
    /**
     * Reject the current action and issue a re-route
     * @param routeTo - the route as per
     */
    routeTo(route) {
        let routeTo, onComplete, onAbort;
        if (_.isString(route))
            routeTo = route;
        else
            ({ routeTo, onComplete, onAbort } = route);

        throw {
            canHandleRejection: true,
            routeTo,
            onComplete,
            onAbort,
        };
    },
}

