import Vue from 'vue';

import filters from './submissionsFilters';

export default {
    namespaced: true,
    modules: {
        filters
    },
    state: {
        existingSubmissionIds: undefined,
        imported: [],
        fetchingSubmissions: false,
        fetched: [],
        submissions: {},
        totalInSubmitable: null,
        totalSubmissionsLoaded: 0,
    },
    getters: {
        alreadyImportedCount: ({ existingSubmissionIds, fetched }) => (existingSubmissionIds || []).filter(id => fetched.indexOf(id) > -1).length,
        importedCategoryCount: (state, g, rootState) => {
            return state.imported.reduce((count, subId) => {
                const sub = state.submissions[subId];
                const id = sub.category.category_id;


                let catCount = count[id];

                if (!catCount) {
                    const cats = rootState.categories;
                    catCount = count[id] = {
                        count: 0,
                        category: sub.category,
                        ...cats[cats.submittableMappings[id]]
                    }
                }

                catCount.count++;
                return count;
            }, {})
        },
        pagesAvailable: state => Math.ceil((state.totalInSubmitable || 0) / (state.filters.pageSize || 20)),
        pageCurrentPosition: state => state.totalSubmissionsLoaded / (state.filters.pageSize || 20),
        pageHasMoreSubs: (s, { pageCurrentPosition }) => pageCurrentPosition !== Math.floor(pageCurrentPosition),
        pageNext: (s, { pagesAvailable, pageCurrentPosition, pageHasMoreSubs }) => pagesAvailable === 0 ? 1 : (Math.ceil(pageCurrentPosition) + (pageHasMoreSubs ? 0 : 1)),
        pageHeldBack: (s, { pagesAvailable, pageHasMoreSubs, pageNext }) => pageHasMoreSubs || pagesAvailable !== 0 && pagesAvailable <= pageNext,
        submissionList: ({ existingSubmissionIds, filters, fetched }) => !filters.showImported ? fetched.filter(id => existingSubmissionIds.indexOf(id) === -1) : fetched,
        submissionById: state => id => state.submissions[id],
        unproccessedCount: ({ filters, imported, totalInSubmitable }, getters) => totalInSubmitable === null ? null : totalInSubmitable - imported.length - (filters.showImported ? 0 : getters.alreadyImportedCount),
        urlActiveAccount: (s, g, rs, rootGetters) => rootGetters['organisation/submittable/urlActiveAccount'],
        urlApiAccess: (s, g, rs, rootGetters) => rootGetters['organisation/submittable/urlSubmittbleApiAccess'],
        urlFile: (s, getters) => (submissionId, fileGuid) => `${getters.urlSubmissions}/${submissionId}/file/${fileGuid}`,
        urlSubmissions: (s, getters) => `${getters.urlApiAccess}/submissions`,
    },
    mutations: {
        add(state, submission) {
            const id = submission.submission_id;

            if (!state.submissions[id]) {
                Vue.set(state.submissions, id, submission);
            }

            // If we already fetched this submission then just skip this
            if (state.fetched.indexOf(id) > -1)
                return;

            const currentStatus = state.filters.status.toLowerCase();
            if (currentStatus === 'all' || submission.status.toLowerCase() === currentStatus)
                state.fetched.push(id);

            state.totalSubmissionsLoaded++;
        },
        loadExistingSubs(state, existingSubs) {
            Vue.set(state, 'existingSubmissionIds', existingSubs);
        },
        imported(state, { submission_id: id }) {
            state.imported.push(id);
            if (state.existingSubmissionIds) state.existingSubmissionIds.push(id);
        },
        resetSubmissions(state, isAccountChange = true) {
            // Set it back to initial state
            if (isAccountChange) {
                Vue.set(state, 'imported', []);
                Vue.set(state, 'submissions', {});
            }
            Vue.set(state, 'fetched', []);
            Vue.set(state, 'totalInSubmitable', null);
            Vue.set(state, 'totalSubmissionsLoaded', 0);
        },
        setFetchingSubmissions(state, isFetching) {
            state.fetchingSubmissions = !!isFetching;
        },
        setTotalInSubmitable(state, total) {
            state.totalInSubmitable = total;
        },
        submissionAlreadyExists(state, submissionId) {
            state.existingSubmissionIds.push(submissionId);
        },
    },
    actions: {
        downloadFile({ commit, dispatch, getters, state }, { submissionId, fileId }) {
            if (!submissionId || !fileId) return Promise.reject('A submission id && file id are required.');

            const submission = state.submissions[submissionId];
            const fileField = submission.form.items.find(i => i.type === 'FILE_UPLOAD') || [];
            const file = fileField.files.find(f => f.id === fileId);

            const { fileName } = file;

            commit('files/setDownloading', { fileId, isDownloading: true }, { root: true, });
            return this.$http
                .get(getters.urlFile(submissionId, fileId), { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: response.headers['content-type']
                    })
                    const link = document.createElement('a')
                    link.download = fileName
                    link.href = window.URL.createObjectURL(blob)
                    link.style.position = 'fixed';
                    link.target = '_blank';
                    link.click()

                    commit('files/setDownloading', { fileId, isDownloading: false }, { root: true, });
                })
                .catch(err => dispatch('organisation/submittable/handleSubmittableError', err, { root: true }))
        },
        fetch({ commit, dispatch, getters, state }) {
            if (state.fetchingSubmissions) return;
            commit('setFetchingSubmissions', true);

            const { pageNext, pageHeldBack: pageWasHeldBack } = getters;

            if (!state.existingSubmissionIds)
                dispatch('loadExistingSubs')

            let pageSize = state.filters.pageSize || 20;
            const queryFilter = getters['filters/query'];

            return this.$http
                .get(`${getters.urlSubmissions}?page=${pageNext}&pageSize=${pageSize}${queryFilter}`)
                .then(({ data }) => {
                    data.items.forEach(sub => commit('add', sub));

                    // Set the new total for submissions in Submittable
                    commit('setTotalInSubmitable', data.total_items);

                    // We have now finished fetching the submissions, so a new fetch is possible
                    commit('setFetchingSubmissions', false);

                    // If we held the page back or we have less than 5 subs and there is another page, 
                    // then lets also grab that now
                    const loadedSubmissionLength = getters.submissionList.length;
                    if (((pageWasHeldBack && loadedSubmissionLength / pageSize < 0.6) || loadedSubmissionLength <= 5) && getters.pagesAvailable > pageNext)
                        return dispatch('fetch');
                })
                .catch(err => {
                    commit('setFetchingSubmissions', false);
                    if (err.response.status === 404) // Not found suggests that the filter is incorrect or the page is too high
                        return;
                    else
                        return dispatch('organisation/submittable/handleSubmittableError', err, { root: true })
                })
        },
        loadExistingSubs({ commit, getters }) {
            return this.$http.get(`${getters.urlActiveAccount}/importedSubmissions`)
                .then(({ data: existingSubs }) => {
                    commit('loadExistingSubs', existingSubs);
                })
        },
        importSub({ commit, dispatch, getters, state }, submissionId) {
            const submission = state.submissions[submissionId];

            return this.$http
                .post(getters.urlSubmissions, { submission })
                .then(
                    () => {
                        commit('imported', submission);
                        if (getters.submissionList.length <= 5)
                            return dispatch('fetch');
                    },
                    err => {
                        const { data, status } = err.response;
                        if (status === 409 && data.isDuplicate) {
                            commit('submissionAlreadyExists', submission.submission_id);

                            commit('setSnackBackDetails', {
                                isShown: true,
                                text: `<span><em>${submission.title}</em> has already been imported</span>`
                            }, { root: true });

                            if (getters.submissionList.length <= 5)
                                return dispatch('fetch');
                        }
                        else
                            throw err;
                    })
        },
        resetAfterAccountChange({ commit, dispatch }) {
            commit('resetSubmissions');

            return dispatch('loadExistingSubs');
        },
    },
}