<template>
    <v-chip class="overflow-visible"
            :color="statusColor"
            text-color="white"
            label
            :small="small">{{statusText}}</v-chip>
</template>
<script>
    import statusColors from '@/helpers/statusColors';

    export default {
        name: 'status-indicator',
        props: {
            status: {
                type: String,
                required: true,
            },
            small: Boolean,
        },
        computed: {
            statusText() {
                return statusColors[this.status.toLowerCase()]
                    ? this.status
                    : 'Unknown';
            },
            statusColor() {
                return statusColors[this.statusText.toLowerCase()];
            },
        },
    };
</script>