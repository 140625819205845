<template>
    <card-actions>
        <v-btn @click="$emit('select-outcome')"
               color="primary"
               text
               :loading="loading"
               :disabled="disabled">{{outcomes.name}}</v-btn>
        <template #secondary>
            <save-task-btn :task-id="taskId"></save-task-btn>
        </template>
    </card-actions>
</template>
<script>
    import saveTaskBtn from './save-task-btn';

    export default {
        name: 'outcome-actions-sequential',
        components: {
            saveTaskBtn,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
            outcomes: {
                type: Object,
                required: true,
            },
            loading: Boolean,
            disabled: Boolean,
        },
    };
</script>