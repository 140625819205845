<template>
    <v-navigation-drawer v-model="showPageFiltersBound"
                         absolute
                         right
                         temporary
                         class="below-header filters-tray"
                         :class="{'extended-toolbar': extendedToolbar}">
        <portal-target name="page-filters"></portal-target>
    </v-navigation-drawer>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        name: 'page-filters',
        props: {
            extendedToolbar: Boolean,
        },
        computed: {
            showPageFiltersBound: {
                get() {
                    return this.showPageFilters;
                },
                set(newVal) {
                    this.togglePageFilters({ isShown: newVal });
                },
            },
            ...mapState(['showPageFilters']),
        },
        methods: {
            ...mapMutations(['togglePageFilters']),
        },
    };
</script>