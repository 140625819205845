<template>
    <v-app>
        <v-app-bar app
                   color="primary"
                   dark>
            <template v-if="!disableNavigation">
                <v-app-bar-nav-icon @click.stop="showNavigation = !showNavigation"></v-app-bar-nav-icon>
                <v-toolbar-title>{{heading}}</v-toolbar-title>
                <slot name="toolbar"></slot>
            </template>
            <template v-if="extend && !disableNavigation"
                      #extension>
                <slot name="extension"></slot>
            </template>
        </v-app-bar>
        <v-content :class="{'extended-menu': extend}">
            <div v-if="loading || errored"
                 class="d-flex align-center"
                 style="height: 100%;">
                <div class="mx-auto"
                     style="position: relative; top: -12vh">
                    <h2 v-if="loading"
                        class="text-center">Loading...</h2>
                    <template v-else>
                        <h2>Fatal Error</h2>
                        <p>A fatal error has occured and, unfortunately, we are unable to recover from this... :(</p>
                        <p>You can attempt to retry what you were doing, but if the problem persists, please let us know.</p>
                        <v-btn @click="goHome">Home</v-btn>
                    </template>
                </div>
            </div>
            <slot v-else
                  default></slot>
        </v-content>

        <!-- App Navigation -->
        <v-navigation-drawer v-model="showNavigationBound"
                             fixed
                             app
                             temporary>
            <template #prepend>
                <user-indicator></user-indicator>
            </template>
            <v-btn to="/"
                   text
                   block>
                <v-badge v-if="taskCount"
                         :content="taskCount"
                         inline>My Tasks</v-badge>
                <span v-else>My Tasks</span>
            </v-btn>
            <v-btn to="/my-tasks/history"
                   text
                   block>
                <span>Editor History</span>
            </v-btn>
            <template v-if="showProjects">
                <v-subheader>Active Projects</v-subheader>
                <v-btn v-for="project in projects"
                       :key="project._id"
                       :to="`/projects/${project._id}/worktrays`"
                       text
                       block>
                    <v-badge v-if="project.itemCount"
                             :content="project.itemCount"
                             inline>{{project.name}}</v-badge>
                    <span v-else>{{project.name}}</span>
                </v-btn>
            </template>
            <template v-if="hasSubmittableRole">
                <v-subheader>Submittable</v-subheader>
                <v-btn text
                       block
                       to="/submittable/submissions">Submissions</v-btn>
                <v-btn text
                       block
                       to="/submittable/submissions/syncing">
                    <v-badge v-if="outOfSyncCount"
                             :content="outOfSyncCount"
                             inline>Out of Sync</v-badge>
                    <span v-else>Out of Sync</span>
                </v-btn>
                <v-btn text
                       block
                       to="/submittable/accounts">Manage Accounts</v-btn>
            </template>
            <template v-if="hasAdminRole">
                <v-subheader>Admin</v-subheader>
                <v-btn text
                       block
                       to="/admin/staff">Staff</v-btn>
                <v-btn text
                       block
                       to="/admin/reports">Reports</v-btn>
            </template>
        </v-navigation-drawer>

        <!-- Page Filters -->
        <page-filters :extended-toolbar="extend"></page-filters>

        <app-snackbar></app-snackbar>
    </v-app>
</template>

<script>
    import _ from 'lodash';
    import { mapGetters, mapState } from 'vuex';

    import { roles } from '@/helpers/security';

    import appSnackbar from '@/components/app-snackbar';
    import pageFilters from '@/components/page-filters';
    import userIndicator from '@/components/user-indicator';

    export default {
        name: 'app-navigation',
        components: {
            appSnackbar,
            pageFilters,
            userIndicator,
        },
        props: {
            heading: String,
            noMenu: Boolean,
            extend: Boolean,
        },
        data() {
            return {
                showNavigation: false,
            };
        },
        computed: {
            disableNavigation() {
                return this.noMenu || this.errored || this.loading;
            },
            hasSubmittableRole() {
                return this.hasRoles(roles.submittable);
            },
            hasAdminRole() {
                return this.hasRoles(roles.admin);
            },
            showNavigationBound: {
                get() {
                    return this.showNavigation && !this.disableNavigation;
                },
                set(value) {
                    this.showNavigation = value;
                },
            },
            showProjects() {
                return this.hasAdminRole && !_.isEmpty(this.projects);
            },
            ...mapState(['errored', 'loading']),
            ...mapGetters('organisation/projects', ['projects']),
            ...mapState('organisation/submittable/syncing', {
                outOfSyncCount: 'count',
            }),
            ...mapState('myTasks', {
                taskCount: state => state.queue.length,
            }),
            ...mapGetters({
                hasRoles: 'hasRoles',
            }),
        },
        methods: {
            goHome() {
                window.location.href = '/';
            },
        },
    };
</script>
<style scoped>
    .v-navigation-drawer.below-header {
        z-index: 4;
    }

    .filters-tray {
        width: 280px !important;
        height: calc(100vh - 64px) !important;
        position: fixed;
        top: 64px !important;
    }

    .filters-tray.extended-toolbar {
        height: calc(100vh - 112px) !important;
        top: 112px !important;
    }
</style>
<style>
    .stick-to-header {
        position: sticky !important;
        top: 64px;
    }

    .extended-menu .stick-to-header {
        top: 112px;
    }
</style>