<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="min-width">
                <v-card-title class="justify-space-between flex-nowrap">
                    <div :title="`${task.stepName} Notes: ${task.title}`"
                         class="text-truncate pr-2">
                        <span class="mr-2">{{task.stepName}} Notes:</span><em>{{task.title}}</em>
                    </div>
                    <submission-indicators :submission="task"></submission-indicators>
                </v-card-title>
                <v-card-subtitle v-if="task.project"
                                 class="pb-0">{{task.project.name}}</v-card-subtitle>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <flagged-task-alert v-if="task.initialFlaggedWarning"></flagged-task-alert>
            <template v-if="task.files">
                <v-card-subtitle class="pb-0">Files</v-card-subtitle>
                <file-list :files="task.files"
                           @file-selected="downloadFile"></file-list>
            </template>
            <v-card-text>
                <v-textarea v-model="value"
                            @focus="autoSaveIntervalStart"
                            @blur="autoSaveIntervalEnd"
                            label="Notes"
                            outlined
                            :disabled="finalised"
                            :error-messages="isInvalid ? 'Your notes are required' : undefined"
                            rows="5"></v-textarea>
            </v-card-text>
            <card-actions>
                <v-btn @click="finalise"
                       color="primary"
                       text
                       :loading="loading"
                       :disabled="isInvalid || skipping || (finalised && isSaving)">{{finalised? 'Submit Notes': 'Finalise Notes'}}</v-btn>
                <template #secondary>
                    <v-btn v-if="finalised"
                           key="edit"
                           @click="finalised = false"
                           text>Edit Notes</v-btn>
                    <v-btn v-if="!finalised && !task.fieldRequired"
                           key="skip"
                           @click="skip"
                           text
                           :loading="skipping"
                           :disabled="loading">Skip These Notes</v-btn>
                    <save-task-btn :task-id="taskId"></save-task-btn>
                </template>
            </card-actions>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapState } from 'vuex';

    import flaggedTaskAlert from './flagged-task-alert';
    import fileList from '@/components/file-list';
    import saveTaskBtn from './save-task-btn';
    import submissionIndicators from '@/components/submission-indicators';

    export default {
        name: 'task-collect-collated-notes',
        components: {
            flaggedTaskAlert,
            fileList,
            saveTaskBtn,
            submissionIndicators,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            autoSaveInterval: undefined,
            autoSaveStartingValue: undefined,
            finalised: false,
            loading: false,
            skipping: false,
        }),
        computed: {
            value: {
                get() {
                    return this.modifications.value === ''
                        ? ''
                        : this.modifications.value || this.task.fieldValue;
                },
                set(value) {
                    this.$store.commit('myTasks/setCollatedNotesTaskValue', {
                        taskId: this.taskId,
                        value,
                    });
                },
            },
            isInvalid() {
                return this.value === '' && this.task.fieldRequired;
            },
            modifications() {
                return this.task.modifications;
            },
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
                isSaving: function(state) {
                    return !!state.savingTasks[this.taskId];
                },
            }),
        },
        methods: {
            autoSave() {
                if (this.autoSaveStartingValue !== this.value)
                    this.$store.dispatch('myTasks/save', {
                        taskId: this.taskId,
                        isAutosave: true,
                    });

                this.autoSaveStartingValue = this.value;
            },
            autoSaveIntervalStart() {
                this.autoSaveStartingValue = this.value;
                this.autoSaveInterval = setInterval(() => {
                    this.autoSave();
                }, 60000);
            },
            autoSaveIntervalEnd() {
                this.autoSave();
                if (this.autoSaveInterval) clearInterval(this.autoSaveInterval);
                this.autoSaveInterval = undefined;
            },
            downloadFile(fileId) {
                this.$store.dispatch('myTasks/downloadSubmissionFile', {
                    taskId: this.taskId,
                    fileId,
                });
            },
            finalise() {
                if (!this.finalised) {
                    this.$store.commit('myTasks/setCollatedNotesTaskValue', {
                        taskId: this.taskId,
                        value: this.value || '',
                    });
                    if (!this.isInvalid) this.finalised = true;
                } else {
                    this.loading = true;
                    this.$store
                        .dispatch('myTasks/collatedNotesFinalise', {
                            taskId: this.taskId,
                        })
                        .then(() => {
                            this.loading = false;
                        });
                }
            },
            skip() {
                this.skipping = true;
                this.$store
                    .dispatch('myTasks/dismissTask', {
                        taskId: this.taskId,
                    })
                    .then(() => {
                        this.skipping = false;
                    });
            },
        },
        created() {
            this.autoSaveStartingValue = this.task.fieldValue || '';
        },
        beforeDestroy() {
            if (this.autoSaveInterval) this.autoSaveIntervalEnd();
        },
    };
</script>