import store from '@/store'
import beforeEnterGaurd from './beforeEnterGuard';
import { roles } from '@/helpers/security'

const projectAccess = [roles.admin]

export default {
    routes: [
        {
            path: ':id/worktrays',
            name: 'Project Worktrays',
            component: () => import(/* webpackChunkName: "submittable-accounts" */ '@/modules/projects/views/worktrays-board.vue'),
            props: true,
            meta: {
                roles: projectAccess
            },
            beforeEnter: (to, from, next) => beforeEnterGaurd(store, to, from, next),
        },
        {
            path: ':id/assigned',
            name: 'Assigned Submissions',
            component: () => import(/* webpackChunkName: "assigned-board" */ '@/modules/projects/views/assigned-board.vue'),
            props: true,
            meta: {
                roles: projectAccess
            },
            beforeEnter: (to, from, next) => beforeEnterGaurd(store, to, from, next, true),
        },
        {
            path: ':id/finalised',
            name: 'Finalised Submissions',
            component: () => import(/* webpackChunkName: "finalised-board" */ '@/modules/projects/views/finalised-board.vue'),
            props: true,
            meta: {
                roles: projectAccess
            },
            beforeEnter: (to, from, next) => beforeEnterGaurd(store, to, from, next, true),
        },
        {
            path: ':projectId/submissions/:submissionId',
            name: 'Project: Submission Detais',
            component: () => import(/* webpackChunkName: "submittable-accounts" */ '@/modules/projects/views/submission-view.vue'),
            props: true,
            meta: {
                roles: projectAccess
            },
            beforeEnter: (to, from, next) => beforeEnterGaurd(store, to, from, next, true),
        }, {
            path: ':id/issues',
            name: 'Project: Submissions With Issues',
            component: () => import(/* webpackChunkName: "submittable-accounts" */ '@/modules/projects/views/submissions-with-issues.vue'),
            props: true,
            meta: {
                roles: projectAccess
            },
            beforeEnter: (to, from, next) => beforeEnterGaurd(store, to, from, next, true),
        },
    ],
}