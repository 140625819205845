<template>
    <card-actions>
        <v-btn @click="selectOutcome()"
               color="primary"
               text
               :loading="loading && !loadingOverride"
               :disabled="disabled || loading && loadingOverride">Submit</v-btn>
        <template #secondary>
            <template v-for="override in availableOverrides">
                <v-btn :key="override._id + 'btn'"
                       @click="confirmOverride(override._id, override.message)"
                       :loading="loading && loadingOverride === override._id"
                       :disabled="disabled || loading && loadingOverride !== override._id"
                       text>{{override.name}}</v-btn>
                <!--Dialog to confirm the override action, where appropriate-->
                <v-dialog v-if="override.message"
                          :key="override._id +'dialog'"
                          :value="overrideToConfirm === override._id"
                          @input="confirmInputChanged"
                          max-width="500">
                    <v-card>
                        <v-card-title class="text-truncate">{{task.title}}</v-card-title>
                        <v-card-text>{{override.message}}</v-card-text>
                        <card-actions>
                            <v-btn color="primary"
                                   text
                                   @click="selectOutcome(override._id)">
                                {{override.name}}
                            </v-btn>

                            <template #secondary>
                                <v-btn color="orange darken-1"
                                       text
                                       @click="overrideToConfirm = null">
                                    Cancel
                                </v-btn>
                            </template>
                        </card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <save-task-btn :task-id="taskId"></save-task-btn>
        </template>
    </card-actions>
</template>
<script>
    import { mapState } from 'vuex';
    import thresholdIsMetByValue from '@/helpers/thresholdIsMetByValue';

    import saveTaskBtn from './save-task-btn';

    export default {
        name: 'outcome-actions-thresholded',
        components: {
            saveTaskBtn,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
            loading: Boolean,
            disabled: Boolean,
        },
        data() {
            return {
                loadingOverride: null,
                overrideToConfirm: null,
            };
        },
        computed: {
            availableOverrides() {
                if (!this.thresholdValue) return [];

                return this.outcomes.overrides.reduce((available, override) => {
                    if (
                        thresholdIsMetByValue(
                            override.threshold,
                            this.thresholdValue
                        )
                    )
                        available.push(override);

                    return available;
                }, []);
            },
            field() {
                return this.task.fields[this.outcomes.fieldId];
            },
            outcomes() {
                return this.task.outcomes;
            },
            thresholdValue() {
                return this.field.value;
            },
            ...mapState('myTasks', {
                task(state) {
                    return state.tasks[this.taskId];
                },
            }),
        },
        methods: {
            confirmInputChanged(newValue) {
                if (!newValue) this.overrideToConfirm = null;
            },
            confirmOverride(overrideId, message) {
                return message
                    ? (this.overrideToConfirm = overrideId)
                    : this.selectOutcome(overrideId);
            },
            selectOutcome(overrideId) {
                this.overrideToConfirm = null;
                this.loadingOverride = overrideId;
                this.$emit('select-outcome', overrideId);
            },
        },
    };
</script>