import Vue from 'vue';

export default {
    state: {},
    getters: {
        worktrayHasDraft: (state, getters) => state[getters.worktrayActiveId],
    },
    mutations: {
        draftSetExists(state, { worktrayId, hasDraft }) {
            Vue.set(state, worktrayId, hasDraft)
        },
    },
    actions: {
        draftCheckExists({ commit, getters }) {
            const worktrayId = getters.worktrayActiveId;
            commit('draftSetExists', {
                worktrayId,
                hasDraft: !!localStorage.getItem(draftKey(getters.worktrayActiveId))
            });
        },
        draftLoadFromStorage({ dispatch, getters }) {
            const json = localStorage.getItem(draftKey(getters.worktrayActiveId));

            if (json) dispatch('draftLoad', JSON.parse(json));
        },
        draftLoad({ commit, dispatch, getters }, { worktrayId, assigned, staffLocked } = {}) {
            if (worktrayId === getters.worktrayActiveId) {
                const existingAssigned = getters.worktrayActiveAssignments;
                const subs = getters.submissions;
                const staff = getters.staffAssignableForActiveWorktray;
                const cats = getters.worktrayCategories;

                if (Array.isArray(assigned)) {
                    assigned.forEach(a => {
                        if (!existingAssigned[a.submissionId] &&
                            subs.find(s => s._id === a.submissionId) &&
                            staff.find(s => s._id === a.assigneeId) &&
                            cats.indexOf(a.categoryId) > -1) {
                            commit('assign', a);
                        }
                    });
                }

                if (Array.isArray(staffLocked)) {
                    staffLocked.forEach(staffId => {
                        if (staff.find(s => s._id === staffId))
                            dispatch('setStaffIsLockedForActiveWorktray', { staffId, isLocked: true });
                    });
                }
            }
        },
        draftSave({ commit, getters }) {
            const draftAssigned = Object.values(getters.worktrayActiveAssignments);

            const locked = getters.staffLockedForActiveWorktray;
            const draftLockedStaff = !locked
                ? []
                : getters.staffAssignableForActiveWorktray.reduce((lockedStaff, s) => {
                    if (locked[s._id]) lockedStaff.push(s._id);
                    return lockedStaff;
                }, []);


            const worktrayId = getters.worktrayActiveId;

            const draft = {
                worktrayId,
                assigned: draftAssigned,
                staffLocked: draftLockedStaff
            }

            localStorage.setItem(draftKey(worktrayId), JSON.stringify(draft));
            commit('draftSetExists', { worktrayId, hasDraft: true });
        },
        clearActiveWorktraysDraft({ commit, getters }) {
            const worktrayId = getters.worktrayActiveId;
            localStorage.removeItem(draftKey(worktrayId))
            commit('draftSetExists', { worktrayId, hasDraft: false });
        }
    }
}

const draftKey = worktrayId => `assignDraft-${worktrayId}`;