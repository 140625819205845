import Vue from 'vue';
import sortSubmissionFields from './sortSubmissionFields';

export default {
    state: {
        _id: undefined,
        details: undefined,
        loading: undefined
    },
    getters: {
        activeSubActiveStep: state => state.details ? state.details.steps.find(s => s.state === 'Active') : undefined,
        urlActiveSubmission: (state, getters) => getters.urlSubmissionById(state._id),
    },
    mutations: {
        loadingSet(state, loading) {
            state.loading = loading;
        },
        activeSubmissionActiveStepDelete(state) {
            const { steps } = state.details;
            const step = steps.find(s => s.state === 'Active');
            if (step)
                Vue.delete(steps, steps.indexOf(step));
        },
        activeSubActiveStepUpdateAssignee(state, { _id, firstName, surname }) {
            const step = state.details.steps.find(s => s.state === 'Active');
            if (step)
                step.assignee = { _id, firstName, surname };
        },
        activeSubmissionIdSet(state, { submissionId }) {
            state._id = submissionId
        },
        activeSubmissionSet(state, submission) {
            if (submission)
                sortSubmissionFields(submission);

            Vue.set(state, 'details', submission);
        },
        activeSubmissionWorktrayAdd(state, worktrayDetails) {
            state.details.project.inWorktrays.push(worktrayDetails);
        },
        activeSubmissionWorktrayRemove(state, { worktrayDetailsId }) {
            const { inWorktrays } = state.details.project;
            const details = inWorktrays.find(d => d._id = worktrayDetailsId);
            if (details)
                Vue.delete(inWorktrays, inWorktrays.indexOf(details));
        },
    },
    actions: {
        activeSubmissionActiveStepDelete({ commit, getters }) {
            commit('loadingSet', 'activeStepDelete');
            return this.$http.delete(`${getters.urlActiveSubmission}/activeStep`)
                .then(() => {
                    commit('activeSubmissionActiveStepDelete');

                    commit('loadingSet');
                    commit('setSnackBackDetails', {
                        text: 'Active step has been deleted',
                        isShown: true,
                    }, { root: true });
                })
        },
        activeSubmissionLoad({ commit, getters }, { submissionId }) {
            if (!this.submissionViewed || this.submissionViewed._id !== submissionId) {
                commit('activeSubmissionIdSet', { submissionId })
                commit('updateStatus', { isLoading: true }, { root: true });

                // Clear the loaded sub
                commit('activeSubmissionSet');
                return this.$http
                    .get(getters.urlActiveSubmission)
                    .then(({ data: sub }) => {
                        commit('activeSubmissionSet', sub);
                        commit('updateStatus', { isLoading: false }, { root: true });
                    });
            }
        },
        activeSubmissionDelete({ commit, dispatch, getters, state }) {
            const subTitle = state.details.title;
            commit('loadingSet', 'delete');
            return this.$http.delete(getters.urlActiveSubmission)
                .then(() => {
                    const submissionId = state._id;
                    commit('activeSubmissionIdSet', {});
                    commit('activeProjectWorktrays/submissionHasBeenDeleted', { submissionId });

                    const inWorktrays = state.details.project.inWorktrays;
                    if (inWorktrays && inWorktrays.length)
                        dispatch('activeProjectWorktrays/worktraysUpdateCounts', inWorktrays.map(({ worktrayId }) => ({ worktrayId, addToCount: -1 })));

                    // Clear the loaded sub
                    commit('activeSubmissionSet');
                    commit('loadingSet');

                    commit('setSnackBackDetails', {
                        text: `Deleted '<em>${subTitle}</em>.`,
                        isShown: true,
                    }, { root: true });
                });
        },
        activeSubmissionReassign({ commit, getters, rootState }, { newAssigneeId }) {
            const newAssignee = rootState.organisation.projects.assigning.staffAssignable[newAssigneeId];
            if (!newAssignee) return;

            commit('loadingSet', 'reassign');
            return this.$http.put(`${getters.urlActiveSubmission}/assignee`, { newAssigneeId })
                .then(() => {
                    commit('activeSubActiveStepUpdateAssignee', newAssignee);
                    commit('loadingSet');
                    commit('setSnackBackDetails', {
                        text: `Reassigned to ${newAssignee.firstName}.`,
                        isShown: true,
                    }, { root: true });
                });
        },
        activeSubmissionWorktrayAdd({ commit, dispatch, getters, rootState, state }, { worktrayId, reasonId }) {
            const module = rootState.organisation.projects.activeProjectWorktrays;
            const worktray = module.worktrays[worktrayId];
            if (!worktray) return;
            const isActiveWorktray = module.activeWorktray && worktray._id === module.activeWorktray._id;

            const reason = worktray.reasons.find(r => r._id === reasonId);
            if (!reason) return;

            commit('loadingSet', 'worktrayAdd');
            return this.$http.post(`${getters.urlActiveSubmission}/worktrays/${worktrayId}/reasons/${reasonId}`)
                .then(() => {
                    const reasonId = reason._id;
                    commit('activeSubmissionWorktrayAdd', {
                        worktrayId: worktray._id,
                        name: worktray.name,
                        reasonId,
                        reasonName: reason.name,
                    });

                    if (isActiveWorktray)
                        commit('activeProjectWorktrays/activeWorktrayItemAdd', { submissionId: state._id, reasonId });

                    dispatch('activeProjectWorktrays/worktraysUpdateCounts', { worktrayId, addToCount: 1 });

                    commit('loadingSet');
                    commit('setSnackBackDetails', {
                        text: `Added to worktray`,
                        isShown: true,
                    }, { root: true });
                })
        },
        activeSubmissionWorktrayRemove({ commit, dispatch, getters, rootState, state }, { worktrayDetailsId }) {
            const { worktrayId, reasonId } = state.details.project.inWorktrays.find(d => d._id === worktrayDetailsId);

            const module = rootState.organisation.projects.activeProjectWorktrays;
            const worktray = module.worktrays[worktrayId];
            const isActiveWorktray = module.activeWorktray && worktray._id === module.activeWorktray._id;

            commit('loadingSet', 'worktrayRemove');
            return this.$http.delete(`${getters.urlActiveSubmission}/worktrays/${worktrayId}/reasons/${reasonId}`)
                .then(() => {
                    commit('activeSubmissionWorktrayRemove', { worktrayDetailsId });

                    if (isActiveWorktray)
                        commit('activeProjectWorktrays/activeWorktrayItemRemove', { submissionId: state._id, reasonId });

                    dispatch('activeProjectWorktrays/worktraysUpdateCounts', { worktrayId, addToCount: -1 });

                    commit('loadingSet');
                    commit('setSnackBackDetails', {
                        text: `Removed from worktray`,
                        isShown: true,
                    }, { root: true });
                });
        }
    }
}