export default function thresholdIsMetByValue(
    { useEquals, useLessThan, threshold: { options, value } },
    testValue
) {
    let thresholdGradeIndex = options.indexOf(value);
    let valueIndex = options.indexOf(testValue);

    return thresholdGradeIndex < 0
        ? false
        : (useEquals && thresholdGradeIndex === valueIndex) ||
        // In this case, the lower the index the higher the grade, which is a touch counter intuitive
        // in logic, but makes sense in terms of data
        (useLessThan && thresholdGradeIndex < valueIndex) ||
        (!useLessThan && thresholdGradeIndex > valueIndex);
}