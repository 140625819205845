import Vue from 'vue';


export default {
    namespaced: true,
    state: {
        count: null,
        submissions: {},
    },
    getters: {
        baseUrl: (s, g, rs, rootGetters) => `${rootGetters['organisation/submittable/urlBase']}/syncing`,
    },
    mutations: {
        clearSubmissions(state) {
            Vue.set(state, 'submissions', {});
        },
        loadSubmission(state, submission) {
            Vue.set(state.submissions, submission._id, submission);
        },
        removeSubmission(state, submissionId) {
            Vue.delete(state.submissions, submissionId);
            state.count--;
        },
        setCount(state, count) {
            state.count = count;
        },
    },
    actions: {
        getOutOfSyncSubmissions({ dispatch, getters, }) {
            return this.$http
                .get(getters.baseUrl)
                .then(({ data: subs }) => {
                    dispatch('loadSubmissions', subs);
                    dispatch('updateCount', subs.length);
                });
        },
        loadSubmissions({ commit, dispatch }, submissions) {
            if (Array.isArray(submissions)) return submissions.forEach(s => { dispatch('loadSubmissions', s) });
            commit('loadSubmission', submissions)
        },
        resyncSubmission({ commit, getters, dispatch }, { submissionId }) {
            return this.$http.post(`${getters.baseUrl}/${submissionId}`)
                .then(({ data: sub }) => {
                    if (!sub) commit('removeSubmission', submissionId);
                    else {
                        dispatch('loadSubmissions', sub);
                        commit('setSnackBackDetails', {
                            isShown: true,
                            text: `<span><em>${sub.title}</em> was resynced but still does not have the correct status.</span>`,
                        }, { root: true })
                    }
                    return sub;
                });
        },
        switchOrganisation({ commit, dispatch }, { count, submissions }) {
            commit('clearSubmissions');
            commit('setCount', count);

            if (submissions) dispatch('loadSubmissions', submissions);
        },
        updateCount({ commit }, count) {
            commit('setCount', count);
        },
    }
}