import _ from 'lodash'
import VueRouter from 'vue-router'

VueRouter.prototype.$loadModules = function (moduleDetails) {
    _.forOwn(moduleDetails, (module, name) => {
        const routes = module.routes;
        if (routes && routes.length) {
            routes.forEach(route => {
                route.meta = route.meta || {};
                route.meta.$moduleName = name;
                route.path = `/${name}/${route.path}`
            })
            this.addRoutes(routes);
        }

        ['beforeEach', 'beforeResolve', 'afterEach']
            .forEach(guardName => {
                (module[guardName] || [])
                    .forEach(guard => {
                        this[guardName]((to, from, next) => {
                            if (!to.matched ||
                                !to.matched.length ||
                                !to.matched[0].meta.$moduleName ||
                                to.matched[0].meta.$moduleName !== name) {
                                return next();
                            }

                            guard(to, from, (path) => {
                                const test = (path) => _.isString(path) && /^\w/.test(path);
                                if (_.isObject(path) && test(path.path))
                                    path.path = `/${name}/${path.path}`
                                else if (test(path))
                                    path = `/${name}/${path.path}`

                                next(path);
                            });
                        })
                    })
            })

    })
}