import Vue from 'vue';
import _ from 'lodash';

import prependUrl from '@/store/organisationsPrependActiveUrlGetter';

// Modules
import assigning from './assigning';
import cleaning from './cleaning';
import activeSubmission from './activeSubmission';
import activeProjectWorktrays from './activeProjectWorktrays';

export default {
    namespaced: true,
    modules: {
        assigning,
        cleaning,
        activeSubmission,
        activeProjectWorktrays,
    },
    state: {
        activeProject: null,
        allProjects: {},
    },
    getters: {
        activeProjectId: state => state.activeProject._id,
        activeProjectUrl: (s, getters) => `${getters.baseUrl}/${getters.activeProjectId || 'no-project'}`,
        baseUrl: (state, getters) => getters.prependUrl(`/projects`),
        currentOrgProjectIds: (s, g, rs, rootGetters) => (rootGetters['organisation/active'] || {}).projects || [],
        prependUrl,
        projects: (state, getters) => (getters.currentOrgProjectIds || []).reduce((pjs, id) => { pjs[id] = state.allProjects[id]; return pjs; }, {}),
        urlSubmissionById: (s, getters) => id => `${getters.activeProjectUrl}/submissions/${id}`
    },
    mutations: {
        activeProjectUpdateItemCountFromWorktrays(state) {
            const project = state.allProjects[state.activeProject._id];
            state.activeProject.totalItems
                = project.itemCount
                = _.reduce(state.activeProjectWorktrays.worktrays, (total, worktray) => total += worktray.itemCount, 0)
        },
        loadActiveProject(state, project) {
            state.activeProject = {
                ...project,
                worktrays: project.worktrays.map(w => w._id),
            };

            state.allProjects[project._id].itemCount = project.totalItems !== undefined
                ? project.totalItems
                : project.worktrays.reduce((count, w) => count + w.itemCount, 0);
        },
        loadProjects(state, projects) {
            const loadProject = (project) => { Vue.set(state.allProjects, project._id, project); };

            if (_.isArray(projects)) return projects.forEach(loadProject);
            if (_.isObject(projects)) return loadProject(projects);
        },
    },
    actions: {
        activeProjectLoad({ commit, state }, project) {
            if (!state.activeProject || state.activeProject._id !== project._id)
                commit('activeProjectWorktrays/projectHasChanged');

            commit('loadActiveProject', project);

            project.worktrays.forEach(w => commit('activeProjectWorktrays/worktrayLoad', w));
        },
        changeActiveProject({ commit, dispatch, getters, state }, { projectId, forceReload }) {
            if (forceReload)
                commit('assigning/setConfirmAssignments', false);

            const activeWorktray = state.activeProjectWorktrays.activeWorktray;

            return !forceReload && state.activeProject && state.activeProject._id === projectId
                ? Promise.resolve(true)
                : this.$http
                    .get(`${getters.baseUrl}/${projectId}?includeWorktrayAndStaff=true${forceReload && activeWorktray ? `&worktrayId=${activeWorktray._id}` : ''}`)
                    .then(resp => {
                        if (!resp || !resp.data) return false;

                        return dispatch('activeProjectLoad', resp.data.project)
                            .then(() => {
                                if (resp.data.worktray)
                                    dispatch('activeProjectWorktrays/activeWorktrayLoad', { worktray: resp.data.worktray });

                                if (resp.data.staff)
                                    commit('assigning/staffAssignableLoad', {
                                        staff: resp.data.staff,
                                        forceReload: true,
                                    });

                                return true;
                            });
                    })
                    .catch(err => {
                        const resp = err.response;

                        if (
                            resp &&
                            resp.status === 404 &&
                            resp.data &&
                            resp.data.missingProject
                        ) {
                            return false;
                        }

                        throw err;
                    })
        },
        fileDownload({ commit, getters, state }, { submissionId, fileId, fileName }) {
            if (!submissionId || !fileId) return Promise.reject('A submission id && file id are required.');

            if (!fileName) {
                // find the submission
                const sub = state.activeSubmission._id === submissionId
                    ? state.activeSubmission.details
                    : state.activeProjectWorktrays.submissionsLoaded[submissionId];

                fileName = sub.files.find(f => f._id === fileId).name
            }

            commit('files/setDownloading', { fileId, isDownloading: true }, { root: true, });
            return this.$http
                .get(getters['activeProjectWorktrays/urlFile'](submissionId, fileId), { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: response.headers['content-type']
                    })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = fileName
                    link.click()

                    commit('files/setDownloading', { fileId, isDownloading: false }, { root: true, });
                });
        },
        saveCategoryDetails({ commit, getters, rootState }, { _id }) {
            const cat = rootState.categories[_id];

            return this.$http
                .put(`${getters.activeProjectUrl}/categories/${_id}`, {
                    name: cat.name,
                    colorHex: cat.colorHex
                })
                .then(({ data: savedCat }) => {
                    commit('categories/load', { category: savedCat, }, { root: true });

                    return savedCat;
                });

        },
    }
};