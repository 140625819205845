import _ from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'
import unauthenticated from '@/views/401.vue'
import unauthorized from '@/views/403.vue'
import notFound from '@/views/404.vue'
import taskList from '@/modules/my-tasks/views/task-list'
import unknownUser from '@/views/unknown-user.vue'
import store from '@/store'

// Modules
require('./loadModules')
import { routes as admin } from '@/modules/admin'
import { routes as myTasks } from '@/modules/my-tasks'
import { routes as projects } from '@/modules/projects'
import { routes as submittable } from '@/modules/submittable'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: taskList,
  },
  {
    path: '/401',
    name: 'Unauthenticated',
    component: unauthenticated,
    meta: {
      noInitialise: true
    },
  },
  {
    path: '/403',
    name: 'Unauthorized',
    component: unauthorized,
  },
  {
    path: '/unknown-user',
    name: 'Unknown User',
    component: unknownUser,
    props: true,
    meta: {
      noInitialise: true
    }
  },
  {
    path: '*',
    name: 'Not Found',
    component: notFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Make sure we have initialised before going into any routes
 */
router.beforeEach(function (to, from, next) {
  store.commit('updateStatus', { isLoading: true });

  // Close the page filters when navigating
  store.commit('togglePageFilters', { isShown: false });

  if (!store.state.initialised && !to.meta.noInitialise) {
    return store.dispatch('initialise')
      .then(() => next())
      .catch(next);
  }
  else
    next();
})

/**
 * Make sure the user has the requried access
 */
router.beforeEach(function (to, from, next) {
  const requiredRoles = _.compact(to.matched.map(t => { if (t.meta) return t.meta.roles }))

  if (store.getters.hasRoles(requiredRoles))
    next()
  else
    next('/403');
})


router.afterEach(to => {
  store.commit('updateStatus', { isLoading: false });
  document.title = `${to.name} - Brink Submission Manager`;
});

if (process.env.NODE_ENV !== 'production') {
  router.afterEach(() => {
    document.title = `${store.state.user.firstName} - ${document.title}`;
  });
}

router.$loadModules({ projects });
router.$loadModules({ submittable });
router.$loadModules({ admin });
router.$loadModules({ 'my-tasks': myTasks });

export default router
