import _ from 'lodash';

const hasOwnProperty = Object.prototype.hasOwnProperty.call;

export default {
    namespaced: true,
    state: {
        categories: [-1],
        pageSize: 20,
        searchTerm: undefined,
        showImported: false,
        status: 'new',
        statusHasBeenChanged: false,
        filterChangetimeout: undefined,
        submissionsSort: 'submitted',
        submissionsSortAscending: true,
    },
    getters: {
        availableCategories: (s, g, rootState) => {
            const availableCats = rootState.organisation.submittable.categories;
            if (!availableCats) return;

            return availableCats;
        },
        query: (s, getters) => `${getters.querySort}${getters.queryStatus}${getters.querySearch}${getters.queryCategories}`,
        queryCategories: ({ categories }) => categories.indexOf(-1) >= 0 ? '' : categories.reduce((query, catId, i) => `${query}&category[${i}]=${catId}`, '') || '',
        querySearch: state => state.searchTerm ? `&search=${state.searchTerm}` : '',
        queryStatus: ({ status }) => status === 'all' ? '' : `&status=${status}`,
        querySort: state => `&sort=${state.submissionsSort}&dir=${state.submissionsSortAscending ? 'asc' : 'desc'}`
    },
    mutations: {
        setTimeout(state, filterChangetimeout) {
            state.filterChangetimeout = filterChangetimeout;
        },
        updateSettings(state, newSettings) {
            _.forOwn(newSettings, (value, name) => { state[name] = value });

            // Persist everything apart from excluded categories
            // const saveDisplay = JSON.parse(JSON.stringify(state));
            // delete saveDisplay.categoriesToExclude;
            // localStorage.setItem('assigningWorktrayDisplaySettings', JSON.stringify(saveDisplay));
        },
    },
    actions: {
        updateSettings({ commit, dispatch, state }, newSettings) {
            const statusChange = newSettings.status && newSettings.status !== state.status;

            const newCats = newSettings.categories;
            if (newCats && !newCats.length) newCats.push(-1);
            const cats = state.categories
            const categoryChange = newCats && (
                newCats.length !== cats.length ||
                newCats.map(c => cats.indexOf(c) >= 0)
                    .filter(c => !c)
                    .length > 0
            );

            if (statusChange && !state.statusHasBeenChanged) {
                newSettings.showImported = true
                newSettings.statusHasBeenChanged = true;
            }

            commit('updateSettings', newSettings);


            if (statusChange ||
                categoryChange ||
                hasOwnProperty(newSettings, 'searchTerm') ||
                hasOwnProperty(newSettings, 'submissionsSort') ||
                hasOwnProperty(newSettings, 'submissionsSortAscending')
            ) {
                commit('organisation/submittable/submissions/resetSubmissions', false, { root: true });

                if (state.filterChangetimeout)
                    clearTimeout(state.filterChangetimeout);

                commit('setTimeout',
                    setTimeout(() => {
                        commit('setTimeout');
                        dispatch('organisation/submittable/submissions/fetch', undefined, { root: true });
                    }, 1500)
                );
            }
        }
    }
}