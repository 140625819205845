

// WCAG Spec: https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
export function luminanace(r, g, b) {
    if (isRgb(r)) ({ r, g, b } = r);
    var a = [r, g, b].map(v => {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function textColorForBg(bgColor) {
    const wContrast = contrast(bgColor, '#fff');
    const blackContrast = contrast(bgColor, '#000');

    return wContrast >= blackContrast ? 'white' : 'black';
}

// https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
export function contrast(color1, color2) {
    var lum1 = luminanace(ensureRgb(color1));
    var lum2 = luminanace(ensureRgb(color2));
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}

function isRgb(c) {
    return c && typeof c === 'object' & isByte(c.r) && isByte(c.g) && isByte(c.b);
}
function ensureRgb(c) {
    if (isRgb(c)) return c;
    if (typeof c === 'string' && c.startsWith('#') && c.length <= 7) return hexToRgb(c);

    window.console.error('Cannot figure out color, using black');

    return { r: 255, g: 255, b: 255, };
}

function isByte(n) { return Number.isFinite(n) && n >= 0 && n <= 255 }

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}