import Vue from 'vue';

export default {
    state: {
    },
    getters: {
        activeProjectId: (s, g, rs, rootGetters) => rootGetters['organisation/projects/activeProjectId'],
        staffLockedForActiveWorktray: (state, getters) => state[worktrayAccessor(getters.activeProjectId, getters.worktrayActiveId)] || {},
    },
    mutations: {
        resetWorktrayLocks(state, { projectId, worktrayId }) {
            Vue.set(state, worktrayAccessor(projectId, worktrayId), {});
        },
        setStaffIsLocked(state, { projectId, worktrayId, staffId, isLocked }) {
            const accessor = worktrayAccessor(projectId, worktrayId);
            let worktrayLockInfo = state[accessor];
            if (!worktrayLockInfo) {
                worktrayLockInfo = {}
                Vue.set(state, accessor, worktrayLockInfo);
            }
            Vue.set(worktrayLockInfo, staffId, isLocked);
        },
    },
    actions: {
        resetActiveWorktrayLocks({ commit, getters }) {
            const { activeProjectId: projectId, worktrayActiveId: worktrayId } = getters;
            commit('resetWorktrayLocks', { projectId, worktrayId });
        },
        setStaffIsLockedForActiveWorktray({ commit, getters }, { staffId, isLocked }) {
            const { activeProjectId: projectId, worktrayActiveId: worktrayId } = getters;
            commit('setStaffIsLocked', { projectId, worktrayId, staffId, isLocked });
        },
    }
}

const worktrayAccessor = (projectId, worktrayId) => `${projectId}-${worktrayId}`;