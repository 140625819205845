<template>
    <v-icon color="red accent-4"
            class="mr-2"
            :title="flaggedItemMsg"
            size="32">flag</v-icon>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'submission-flag',
        props: {
            title: String,
        },
        computed: {
            ...mapGetters(['flaggedItemMsg']),
        },
    };
</script>