<template>
    <category-chip v-if="disableEdit"
                   :category-id="boundCategoryId"
                   :breakpoint="breakPoint"
                   :small="small"
                   :close="close"
                   :close-icon="closeIcon"
                   :submittable-category-id="submittableCategoryId"
                   :submittable-id-as-value="submittableIdAsValue">
        <template #right>
            <slot name="right"></slot>
        </template>
    </category-chip>
    <div v-else
         class="editable-cat-wrapper">
        <v-menu v-model="menuOpen"
                offset-y
                :close-on-content-click="false">
            <template #activator="{ on }">
                <category-chip v-on="on"
                               :category-id="boundCategoryId"
                               :breakpoint="breakPoint"
                               :small="small"
                               :close="close"
                               :close-icon="closeIcon"
                               :submittable-category-id="submittableCategoryId"
                               :submittable-id-as-value="submittableIdAsValue">
                    <template #right>
                        <slot name="right"></slot>
                    </template>
                </category-chip>
            </template>
            <v-card class="pt-2">
                <v-text-field v-model="name"
                              label="Name"
                              class="mx-2"></v-text-field>
                <v-chip-group v-model="selectedColorBound"
                              mandatory
                              column
                              class="mx-2 small-check"
                              style="max-width: 200px;">
                    <v-chip v-for="c in colors"
                            :key="c.hex"
                            small
                            filter
                            :color="c.name"
                            :text-color="textColor"></v-chip>
                </v-chip-group>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { textColorForBg } from '@/helpers/colors';

    import categoryChip from './category-chip';

    const colors = [
        { name: 'blue-grey lighten-4', hex: '#CFD8DC' },
        { name: 'pink lighten-4', hex: '#F8BBD0' },
        { name: 'purple lighten-4', hex: '#E1BEE7' },
        { name: 'indigo lighten-4', hex: '#C5CAE9' },
        { name: 'teal lighten-4', hex: '#B2DFDB' },
        { name: 'green lighten-4', hex: '#C8E6C9' },
        { name: 'blue-grey darken-3', hex: '#37474F' },
        { name: 'pink darken-4', hex: '#880E4F' },
        { name: 'purple darken-4', hex: '#4A148C' },
        { name: 'blue darken-4', hex: '#0D47A1' },
        { name: 'teal darken-4', hex: '#00695C' },
        { name: 'light-green darken-4', hex: '#33691E' },
    ];

    export default {
        name: 'category-chip-editable',
        components: {
            categoryChip,
        },
        props: {
            breakPoint: String,
            categoryId: String,
            close: Boolean,
            closeIcon: String,
            disableEdit: Boolean,
            isSubmittableCategory: Boolean,
            small: Boolean,
            submittableCategoryId: Number,
            submittableIdAsValue: Boolean,
        },
        data() {
            return {
                selectedColor: 0,
                colors: colors,
                initialName: undefined,
                initialColor: colors[0].hex,
                menuOpen: false,
            };
        },
        computed: {
            colorBound: {
                get() {
                    return this.colors[this.selectedColor];
                },
            },
            colorHex() {
                const hext = this.colorBound.hex;
                colors.find(f => f.hex === hext);
                this.selected;
                return this.colorBound.hex;
            },
            id() {
                return this.category._id;
            },
            isDirty() {
                return (
                    this.category.name !== this.initialName ||
                    this.colorHex !== this.initialColor
                );
            },
            name: {
                get() {
                    return this.category.name;
                },
                set(newName) {
                    this.updateDetails(newName);
                },
            },
            selectedColorBound: {
                get() {
                    return this.selectedColor;
                },
                set(newColorIndex) {
                    this.selectedColor = newColorIndex;
                    this.updateDetails();
                },
            },
            textColor() {
                return textColorForBg(this.colorHex);
            },
            updateLocation() {
                return `organisation/${
                    this.isSubmittableCategory ? 'submittable' : 'projects'
                }`;
            },
            ...mapState('categories', {
                boundCategoryId(state) {
                    return (
                        this.categoryId ||
                        state.submittableMappings[this.submittableCategoryId]
                    );
                },
                category(state) {
                    return state[this.boundCategoryId] || {};
                },
            }),
        },
        watch: {
            menuOpen(isOpen, wasOpen) {
                if (!isOpen && wasOpen) this.saveDetails();
            },
            category(newCat) {
                // If the category changes, it means it has been loaded from the API
                if (Object.keys(newCat).length) {
                    const color = colors.find(f => f.hex === newCat.colorHex);
                    if (color) {
                        this.selectedColor = colors.indexOf(color);
                        this.initialColor = color;
                    }
                    this.initialName = newCat.name;
                }
            },
        },
        methods: {
            updateDetails(name) {
                this.$store.commit('categories/updateDetails', {
                    id: this.id,
                    colorHex: this.colorHex,
                    name,
                });
            },
            saveDetails() {
                if (!this.name) {
                    this.name = this.initialName;
                    this.setSnackBackDetails({
                        isShown: true,
                        text:
                            'It is not possible to save a category without a name',
                    });
                }

                if (this.isDirty) {
                    this.$store
                        .dispatch(`${this.updateLocation}/saveCategoryDetails`, {
                            _id: this.id,
                        })
                        .then(cat => {
                            this.initialName = cat.name;
                            this.initialColor = cat.colorHex;
                        });
                }
            },
            ...mapMutations(['setSnackBackDetails']),
        },
        created() {
            const cat = this.category,
                catHex = cat.colorHex;

            const startColor = colors.find(c => c.hex === catHex);

            if (startColor) {
                this.selectedColor = colors.indexOf(startColor);
                this.initialName = cat.name;
                this.initialColor = startColor.colorHex;
            }
        },
    };
</script>
<style>
    .small-check .v-chip__content > .v-icon.v-chip__filter {
        margin: 0;
        position: absolute;
        right: 0.15em;
        font-size: 1.5em;
    }
</style>
<style scoped>
    /* Correct a weird height issue */
    .editable-cat-wrapper {
        margin-top: -1.719px;
    }
    .change-span {
        position: absolute;
        bottom: -1.95em;
        right: 0.175em;
        z-index: 2;
    }

    .arrow-up {
        width: 0;
        height: 0;
        position: absolute;
        right: 0.2em;
        top: -0.4em;
        border-left: 0.4em solid transparent;
        border-right: 0.4em solid transparent;
        border-bottom: 0.4em solid #5a5a5a;
    }

    .color-container {
        border: 1px solid #5a5a5a;
        border-radius: 0.125em;
        background-color: white;
        padding: 0.25em;
        display: inline-flex;
    }

    .color-option {
        margin: 0 0.0625em;
        border-radius: 0.125em;
        cursor: pointer;
        height: 1.2em;
        width: 1.2em;
        padding: 0.1em;
    }
</style>