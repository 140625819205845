<template>
    <field-base :value="field.value"
                :field="field"
                :disabled="disabled"
                @collated-notes-modify-editors="collatedNotesModifyEditors($event)">
        <template #append>
            <editor-add :show.sync="addEditorShow"
                        :field="field"
                        :loading="addEditorLoading"
                        :load-users-function="loadAvailableUsers"
                        @add="add"></editor-add>
            <editor-remove :show.sync="removeEditorShow"
                           :field="field"
                           :loading="removeEditorLoading"
                           @remove="remove"></editor-remove>
        </template>
    </field-base>
</template>
<script>
    import { mapGetters } from 'vuex';

    import editorAdd from '@/components/field-collated-notes-editor-add';
    import editorRemove from '@/components/field-collated-notes-editor-remove';
    import fieldBase from '@/components/field-base';

    export default {
        name: 'submission-field-collated-notes',
        components: {
            editorAdd,
            editorRemove,
            fieldBase,
        },
        props: {
            taskId: {
                type: String,
                required: true,
            },
            field: {
                type: Object,
                required: true,
            },
            disabled: Boolean,
        },
        data: () => ({
            addEditorLoading: false,
            addEditorShow: false,
            removeEditorLoading: false,
            removeEditorShow: false,
        }),
        computed: {
            ...mapGetters('myTasks', ['urlForFieldCollatedNotes']),
        },
        methods: {
            add(userId) {
                this.addEditorLoading = true;
                this.$store
                    .dispatch('myTasks/collatedNotesEditorAdd', {
                        taskId: this.taskId,
                        fieldId: this.field._id,
                        userId,
                    })
                    .then(() => {
                        this.addEditorLoading = false;
                        this.addEditorShow = false;
                    });
            },
            collatedNotesModifyEditors({ add, remove }) {
                if (add) {
                    this.addEditorShow = true;
                    this.removeEditorShow = false;
                }
                if (remove) {
                    this.addEditorShow = false;
                    this.removeEditorShow = true;
                }
            },
            loadAvailableUsers() {
                return this.$http.get(
                    `${this.urlForFieldCollatedNotes(
                        this.taskId,
                        this.field._id
                    )}/availableUsers`
                );
            },
            remove(userValueId) {
                this.removeEditorLoading = true;
                this.$store
                    .dispatch('myTasks/collatedNotesEditorRemove', {
                        taskId: this.taskId,
                        fieldId: this.field._id,
                        userValueId,
                    })
                    .then(() => {
                        this.removeEditorLoading = false;
                        this.removeEditorShow = false;
                    });
            },
        },
    };
</script>