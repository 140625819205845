import axios from 'axios';
import { acquireToken } from './auth';

const authTokenHeaderName = 'x-access-token';
let currentAuthToken;

const $http = axios.create({
    baseURL: '/submissions/',
    timeout: 60000
});

$http.interceptors.request.use(async config => {
    const token = await acquireToken();
    config.headers['Authorization'] = `Bearer ${token}`;

    if (currentAuthToken) config.headers[authTokenHeaderName] = currentAuthToken;
    return config;
})

$http.interceptors.response.use((resp) => {
    currentAuthToken = resp.headers[authTokenHeaderName] || currentAuthToken;
    return resp;
})

export default $http;