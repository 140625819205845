<template>
    <app-navigation>
        <div class="d-flex align-center"
             style="height: 100%;">
            <div class="mx-auto text-center"
                 style="position: relative; top: -12vh">
                <h2>404 - Not Found</h2>
                <em>The requested page does not exist..</em>
            </div>
        </div>
    </app-navigation>
</template>

<script>
    import appNavigation from '@/components/app-navigation';

    export default {
        name: 'not-found-404',
        components: {
            appNavigation,
        },
    };
</script>