import Vue from 'vue';
import _ from 'lodash';

export default {
    // Load saved display options
    state: {
        includeUnassigned: true,
        includeAssigned: false,
        categoriesToExclude: {},
        staffShowExistingSubs: true,
        staffLockedHide: false,
        staffLockedSortAtEnd: true,
        staffSort: 'count',
        staffSortAscending: true,
        submissionsSort: 'submitted',
        submissionsSortAscending: true,
        submissionsStaffHideLocked: true,
        // Load any saved settings
        ...JSON.parse(localStorage.getItem('assigningWorktrayDisplaySettings'))
    },
    getters: {
        display: state => state,
    },
    mutations: {
        updateDisplaySettings(state, newSettings) {
            _.forOwn(newSettings, (value, name) => { state[name] = value });

            // Persist everything apart from excluded categories
            const saveDisplay = JSON.parse(JSON.stringify(state));
            delete saveDisplay.categoriesToExclude;
            localStorage.setItem('assigningWorktrayDisplaySettings', JSON.stringify(saveDisplay));
        },
        updateCategoriesToExclude(state, { includeCats, excludeCats }) {
            const savedDetails = state.categoriesToExclude
            includeCats.forEach(c => { Vue.set(savedDetails, c, false) });
            excludeCats.forEach(c => { Vue.set(savedDetails, c, true) });
        },
    }
}